/*eslint-disable */
import { RACThemeProvider, makeStyles } from '@rentacenter/racstrap';
import clsx from 'clsx';

const History = require('history');
import { DofContextProvider } from '../context/DofContext';
// import { Router } from 'react-router';
import { Routes } from './Routes';
import { BrowserRouter } from 'react-router-dom';
import { DigitalOrderFormContextProvider } from '../components/CustomerVerification/Context/DigitialOrderFormContext';
import { ReferenceTabContextProvider } from '../components/CustomerVerification/Context/ReferenceDetailsContext';

import { ResidenceTabContextProvider } from '../components/CustomerVerification/Context/ResidenceDetailsContext';
interface Props {
  history: typeof History;
}

export const appTestId = 'appTestId';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    textAlign: 'center'
  },
}));

export const App = ({ history }: Props) => {
  const { isRenderedByContainer } = window;
  const classes = useStyles();
  const enableLogs = window.sessionStorage.getItem('enableLogs');

  if (enableLogs != '1') {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.log = function () {};
  }

  return (
    <div data-testid={appTestId}>
      <RACThemeProvider>
        <DofContextProvider>
          <DigitalOrderFormContextProvider>
            <ReferenceTabContextProvider>
              <ResidenceTabContextProvider>
                <div className={clsx(classes.container)}>
                  <BrowserRouter>
                    <Routes />
                  </BrowserRouter>

                </div>
              </ResidenceTabContextProvider>
            </ReferenceTabContextProvider>
          </DigitalOrderFormContextProvider>
        </DofContextProvider>
      </RACThemeProvider>
    </div>
  );
};




