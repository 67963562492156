/* eslint-disable */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  RACTextbox,
  RACDatePicker,
  RACSelect,
  RACButton,
  Card,
  Typography,
} from '@rentacenter/racstrap';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { dofContextValue } from '../context/DofContext';
import { dropdown, ExistCustomerDetailsCopy, EmployerDetails } from '../interfaces/interfaces';

import moment from 'moment';
import { DofCssClasses } from '../styles/dofStyles';
import { Validator } from '../utils/utils';
import { useHistory } from 'react-router-dom';
import crypto, { createDecipheriv } from "crypto";
import DofHeaders from './DofHeaders';
import { LoaderPopup } from './LoderPopup';
import {
  CreateCustomer,
  FindCustomer,
  GetApproval,
  GetDropOptions,
  GetPafoneDetails,
  PostNoDeReason,
  RunDecisionEngine,
} from '../api/user';
import { dropOptionsPayload } from '../constants/constants';
import {
  CreateCustomerRequest,
  CreateCustomerResponse,
} from '../interfaces/createCustomer';

import {
  DEAddress,
  DEPhone,
  RunDecisionEngineRequest,
  RunDecisionEngineResponse,
} from '../interfaces/runDecisionEngine';
import { FindCustomerResponse } from '../interfaces/findCustomer';
import { DropOptionsResponse } from '../interfaces/dropOptions';
import { DigitalOrderFormContext } from './CustomerVerification/Context/DigitialOrderFormContext';
import { getPendingPriorityDocumentName, updateNavDetails } from './CustomerVerification/Common/HelperFunctions';
import { getCustomerDetails } from './CustomerVerification/Common/GetCustomerDetails';
import { ResidenceTabContext } from './CustomerVerification/Context/ResidenceDetailsContext';
import { ReferenceTabContext } from './CustomerVerification/Context/ReferenceDetailsContext';
import DatePicker from 'react-datepicker';
import '../styles/DatePickerStyles.css'; // Import the custom styles





export default function PayfonePrefill() {
  // To retrieve the object from session storage
  let dofAuthSessionValuesSession: any = window.sessionStorage.getItem("dofAuthValues");
  let dofAuthValues: any = JSON.parse(dofAuthSessionValuesSession);

  let {
    customerDetails,
    setCustomerDetails,
    formatedMobileNumber, setFormatedMobileNumber,
    employerDetails,
    setEmployerDetails,
    residenceDetails,
    setResidenceDetails,
    referenceDetails,
    setReferenceDetails,
    noDeReasonOptions,
    setNoDeReasonOptions,
    stateOptions,
    setStateOptions,
    bestTimeToCallOptions,
    setBestTimeToCallOptions,
    relationshipOptions,
    setRelationshipOptions,
    govtIdTypeOptions,
    setGovtIdTypeOptions,
    paydayFrequenchOptions,
    setPaydayFrequenchOptions,
    dayPaidOption1,
    setDayPaidOption1,
    dayPaidOption2,
    setDayPaidOption2,
    dayPaidOption3,
    setDayPaidOption3,
    formatedLLPhone,
    setFormatedLLPhone,
    setFormatMobileNumber,
    isDOBVerified, setIsDOBVerified,
    isAddressLine1Verified, setIsAddressLine1Verified,
    isZIPCodeVerified, setIsZipCodeVerified,
    isCityVerified, setIsCityVerified,
    isStateVerified, setIsStateVerified,
    isDOBDisable, setIsDOBDisable,
    setDayPaidOption,
    isVerified,
    setIsVerified

  } = useContext(dofContextValue);
  const {
    landlordResidence,
    setLandlordResidence
  } = useContext(ResidenceTabContext);
  const {
    setFamilyReferenceDetails,
    setnonFamilyReferenceDetails,
    nonFamilyReferenceDetails,
    familyReferenceDetails

  } = useContext(ReferenceTabContext);
  const { setDecisionEngineDetails, setDynamicHeader, setCVOCustomerDetails, setNavHeader } = useContext(DigitalOrderFormContext)
  let dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues")
  let sessionStorageValues: any = JSON.parse(dofAuthSessionValue);

  const dofClassName: any = DofCssClasses();
  // Model Payfone Prefill Resopnse

  const history = useHistory();
  const [isGovtNumOnFocus, setisGovtNumOnFocus] = useState<boolean>(false);

  const [existCustomerDetailsCopy, setExistCustomerDetailsCopy] =
    useState<ExistCustomerDetailsCopy>({
      firstName: '',
      lastName: '',
      markedDOB: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipCode: '',
      email: '',
      markedSsn: '',
      dateOfBirth: '',
    });

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isCustomerExist, setIsCustomerExist] = useState(false);

  const [isExistCustomerPageOpen, setIsExistCustomerPageOpen] =
    useState<boolean>(false);
  let [findindCustomerExisitngFlowOpened, setFindCustomerExisitngFlowOpened] = useState(false)
  let [isPayFoneDetails, setisPayFoneDetails] = useState<boolean>(false);


  const monthIndexes = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  useEffect(() => {
    bindData();
  }, []);


  const getData = (key: string, iv: string, data: string) => {
    console.log('payloadKeys-->', key, iv, data)
    const decipher = createDecipheriv('aes-256-cbc', key, iv);
    let result: any = decipher.update(data, 'hex', 'utf8');
    result += decipher.final('utf8');
    console.log('payloadKeys', iv, key, data, result)
    return result;
  }

  // Function for getting the dropdownoptins
  const getDropdownOptions = async () => {
    if (
      bestTimeToCallOptions.length === 0 ||
      relationshipOptions.length === 0 ||
      govtIdTypeOptions.length === 0 ||
      paydayFrequenchOptions.length === 0 ||
      dayPaidOption1.length === 0 ||
      dayPaidOption2.length === 0 ||
      dayPaidOption3.length === 0
    ) {
      const optionsResponse: DropOptionsResponse | any = await GetDropOptions(
        dropOptionsPayload,
        customerDetails.authKey ? false : customerDetails.storeCode,
        customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode
      );

      const buildDropdownList = (optionsList: any): dropdown[] => {
        const options: dropdown[] = [{ value: '', label: 'Select' }];
        if (
          optionsList !== undefined &&
          optionsList !== null &&
          optionsList.length > 0
        ) {
          optionsList.map((val: any) => {
            options.push({ label: val.description, value: val.referenceCode });
          });
        }

        return options;
      };

      const buildDayPaidOption = (optionsList: any): dropdown[] => {
        const options: dropdown[] = [{ value: '', label: 'Select' }];
        if (
          optionsList !== undefined &&
          optionsList !== null &&
          optionsList.length > 0
        ) {
          optionsList.map((value: any) => {
            options.push({
              label: value.daysPaidDescEn,
              value: value.refCode,
            });
          });
        }
        console.log('Options Return', options);
        return options;
      };

      if (optionsResponse) {
        setBestTimeToCallOptions(
          buildDropdownList(optionsResponse.bestTimeToCall)
        );
        setRelationshipOptions(
          buildDropdownList(optionsResponse.relationshipType)
        );
        setGovtIdTypeOptions(
          buildDropdownList(optionsResponse.governmentTypeId)
        );
        setPaydayFrequenchOptions(
          buildDropdownList(optionsResponse.paySchedule)
        );
        setNoDeReasonOptions(buildDropdownList(optionsResponse.noDeReason));
        setDayPaidOption1(buildDayPaidOption(optionsResponse.dayPaid1?.dayPaid));
        setDayPaidOption2(buildDayPaidOption(optionsResponse.dayPaid2?.dayPaid));
        setDayPaidOption3(buildDayPaidOption(optionsResponse.dayPaid3?.dayPaid));
        const buildStateDropDown: dropdown[] = [];
        optionsResponse.stateOptions.map((value: any, index: any) => {
          if (index == 0) {
            buildStateDropDown.push({ label: 'Select', value: '' });
          }
          buildStateDropDown.push({
            label: value.abbreviation,
            value: value.abbreviation,
          });
        });
        setStateOptions(buildStateDropDown);
      } else {
        history.push('/IdentityVerify?status=Exception');
      }
    }
  };

  //   Employer info screen dropdown data prefil
  const buildDayPaidOptions = async (value: string) => {

    if (value === "WK" || value === "BIWK") {

      setDayPaidOption(dayPaidOption1)

    } else if (value === "SEMI") {

      setDayPaidOption(dayPaidOption3)

    } else if (value === "MON") {

      setDayPaidOption(dayPaidOption2)

    } else {
      setDayPaidOption([{ value: '', label: '' }])
    }
  }
  // Function for  Payfone prefil page data binding
  async function bindData() {
    await getDropdownOptions();
    const findCustomerPayload = {
      firstName: customerDetails.firstName,
      lastName: customerDetails.lastName,
      emailAddress: customerDetails.email,
      phones: [
        {
          phoneNumber: customerDetails.unFormattedPhoneNumber,
        },
      ],
      taxId:
        customerDetails.fullSsn ? customerDetails.fullSsn
          : null,
    };
    debugger
    if (customerDetails?.lastFourSsn && customerDetails.lastFourSsn?.length) {
      setIsLoading(true);
      let payfoneResponse: any = await GetPafoneDetails(
        customerDetails.unFormattedPhoneNumber,
        customerDetails.lastFourSsn,
        customerDetails.authKey ? false : customerDetails.storeCode,
        customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode
      );
      setIsLoading(false);
      payfoneResponse = JSON.parse(payfoneResponse?.body)
      debugger
      console.log("payfoneResponse", payfoneResponse)
      if (
        payfoneResponse &&
        payfoneResponse.response &&
        payfoneResponse.response.phoneNumber
      ) {

        setIsExistCustomerPageOpen(true);
        isPayFoneDetails = true
        setisPayFoneDetails(isPayFoneDetails)
        const clonedCustomerDetails = { ...customerDetails };

        clonedCustomerDetails.firstName =
          payfoneResponse.response.individual.firstName;
        clonedCustomerDetails.lastName =
          payfoneResponse.response.individual.lastName;
        clonedCustomerDetails.fullSsn =
          payfoneResponse.response.individual.ssn;
        clonedCustomerDetails.dateOfBirth =
          payfoneResponse.response.individual.dob;
        clonedCustomerDetails.selectedState =
          payfoneResponse.response.individual.addresses[0].region;
        clonedCustomerDetails.addressLine1 =
          payfoneResponse.response.individual.addresses[0].address;
        clonedCustomerDetails.addressLine2 =
          payfoneResponse.response.individual.addresses[0].extendedAddress;
        (clonedCustomerDetails.city =
          payfoneResponse.response.individual.addresses[0].city),
          (clonedCustomerDetails.zipCode =
            payfoneResponse.response.individual.addresses[0].postalCode);
        clonedCustomerDetails.fullSsn =
          payfoneResponse.response.individual.ssn;

        setCustomerDetails(clonedCustomerDetails);

        setExistCustomerDetailsCopy((event: ExistCustomerDetailsCopy) => ({
          ...event,
          markedDOB:
            moment(
              payfoneResponse.response.individual.dob.toString(),
              'YYYYMMDD'
            )
              .format('MM/DD/')
              .toString() + 'XXXX',
          markedSsn:
            '*****' +
            payfoneResponse.response.individual.ssn.substring(
              payfoneResponse.response.individual.ssn.length - 4
            ),
          addressLine1: payfoneResponse.response.individual.addresses[0].address,
          addressLine2:
            payfoneResponse.response.individual.addresses[0].extendedAddress,
          city: payfoneResponse.response.individual.addresses[0].city,
          state: payfoneResponse.response.individual.addresses[0].region,
          zipCode: payfoneResponse.response.individual.addresses[0].postalCode,
          firstName: payfoneResponse.response.individual.firstName,
          lastName: payfoneResponse.response.individual.lastName




        }));


        setIsDOBVerified(true);
        setIsAddressLine1Verified(true);
        setIsZipCodeVerified(true);
        setIsCityVerified(true);
        setIsStateVerified(true);
        setIsDOBDisable(true);
      }
      else {
        setIsExistCustomerPageOpen(false);

      }
    }
    else if (!customerDetails.lastFourSsn) {
      setIsLoading(false);
      setIsExistCustomerPageOpen(false);
    }
    else {
      setIsLoading(false);
      history.push('/IdentityVerify?status=Exception');
    }
  }

  //  payfone prefill page new code end
  console.log('existingCustomerDetailsdata', existCustomerDetailsCopy)
  const handleInput = (e: any, opt?: string) => {
    if (!opt) {
      if (e.target.name === 'SSN') {
        const response = Validator(e.target.value, 'ssn');
        setCustomerDetails({ ...customerDetails, fullSsn: response[0] });
      } else if (e.target.name === 'Address1') {
        const response = Validator(e.target.value, 'address');
        setCustomerDetails({ ...customerDetails, addressLine1: response[0] });
        setIsAddressLine1Verified(response[1]);
      } else if (e.target.name === 'Address2') {
        const response = Validator(e.target.value, 'address');
        setCustomerDetails({ ...customerDetails, addressLine2: response[0] });
      } else if (e.target.name === 'ZIPCode') {
        const response = Validator(e.target.value, 'zipcode');
        setCustomerDetails({ ...customerDetails, zipCode: response[0] });
        setIsZipCodeVerified(response[1]);
      } else if (e.target.name === 'City') {
        const response = Validator(e.target.value, 'onlyAlphabet');
        setCustomerDetails({ ...customerDetails, city: response[0] });
        setIsCityVerified(response[1]);
      }
    } else if (opt === 'dob') {
      const response = Validator(e, 'DOB');
      setCustomerDetails({ ...customerDetails, dateOfBirth: response[0] });
      setIsDOBVerified(response[1]);
    } else if (opt === 'state') {
      const response = Validator(e.target.value, 'dropdown');
      setCustomerDetails({ ...customerDetails, selectedState: response[0] });
      setIsStateVerified(response[1]);
    }
  };


  // Function for RUN /No Decision Engine Starting
  const validateCustomer = async (address: DEAddress, phone: any, actualCustomerDetails: any) => {
    console.log('validateCustomerresr', address, phone, actualCustomerDetails);

    setIsLoading(true)
    debugger
    if (customerDetails.fullSsn.length > 8) {
      console.log('Inside the customer details', customerDetails);
      const decisionEnginePayload: RunDecisionEngineRequest = {
        firstName: customerDetails.firstName,
        lastName: customerDetails.lastName,
        storeNumber: customerDetails.storeCode,
        address: address,
        dateOfBirth: customerDetails.dateOfBirth,
        globalCustomerId: customerDetails?.globalCustomerId ? customerDetails?.globalCustomerId : actualCustomerDetails?.globalCustomerId,
        phone: phone,
        taxId: customerDetails.fullSsn ? customerDetails.fullSsn : customerDetails.lastFourSsn,
        customerId: customerDetails?.customerId ? customerDetails?.customerId : actualCustomerDetails?.customerId,
        emailAddress: actualCustomerDetails?.emailAddress,
        originator: actualCustomerDetails?.originator,
      };
      const decisionEngineResponse: RunDecisionEngineResponse =
        await RunDecisionEngine(decisionEnginePayload, customerDetails.authKey ? false : customerDetails.storeCode,
          customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode);

      console.log("decisionEngineResponse", decisionEngineResponse)
      debugger
      setIsLoading(false)

      if (
        decisionEngineResponse &&
        (decisionEngineResponse.approvalResponse == '1' ||
          decisionEngineResponse.approvalResponse == '5' ||
          decisionEngineResponse.approvalResponse == '4')
      ) {
        setCustomerDetails({
          ...customerDetails,
          approvalResponse: decisionEngineResponse.approvalResponse,
          weeklyApprovalAmount: decisionEngineResponse.weeklyApprovalAmount,
          totalApprovalAmount: decisionEngineResponse.approvalAmount,
        });
        //Navigate to Basic Infor Page
        if (sessionStorageValues?.dofFeatureFlag == '1' && decisionEngineResponse?.approvalId) {
          setIsLoading(true)
          const request: any = {
            "decisionId": decisionEngineResponse?.approvalId
          }
          const getApprovalServiceResponse = await GetApproval(request, customerDetails.authKey ? false : customerDetails.storeCode,
            customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode)
          setIsLoading(false)
          setDecisionEngineDetails(getApprovalServiceResponse)
          const dynamicrouteRes: any = getPendingPriorityDocumentName(getApprovalServiceResponse)
          const encodedDeId: any = new Buffer(decisionEngineResponse?.approvalId).toString("base64")
          window.sessionStorage.setItem("dofAuthValues", JSON.stringify({ ...dofAuthValues, encodedApprovalId: encodedDeId, approvalId: decisionEngineResponse?.approvalId }))

          updateNavDetails(setDynamicHeader, getApprovalServiceResponse, { identity: false, residence: false, income: false, reference: false, activeStep: false }, setNavHeader)
          getCustomerDetails(
            decisionEngineResponse?.customerId,
            setCVOCustomerDetails,
            landlordResidence,
            setLandlordResidence,
            familyReferenceDetails,
            setFamilyReferenceDetails,
            nonFamilyReferenceDetails,
            setnonFamilyReferenceDetails
          );

          if (dynamicrouteRes) {
            if (dynamicrouteRes == 'identity') {
              history.push(`/dof/verification/${decisionEngineResponse?.approvalId}`)
            }
            else {
              history.push(`/${dynamicrouteRes}/${decisionEngineResponse?.customerId}/${decisionEngineResponse?.approvalId}`)
            }
          }
          else {
            history.push(`/dof/submission/${decisionEngineResponse?.customerId}/${decisionEngineResponse?.approvalId}`);
          }
        }
        else {
          setIsLoading(false)
          history.push('/basicinfo');
        }
      } else {
        setIsLoading(false)
        history.push('/IdentityVerify?status=Rejected');
      }
    } else {
      const postNoDEPayload = {
        globalCustomerId: customerDetails.globalCustomerId ? customerDetails.globalCustomerId : actualCustomerDetails?.globalCustomerId,
        customerId: customerDetails.customerId ? customerDetails.customerId : actualCustomerDetails?.customerId,
        deExceptionReason: noDeReasonOptions[3].value,
      };
      const postNoDEResponse: any = await PostNoDeReason(postNoDEPayload, customerDetails.authKey ? false : customerDetails.storeCode, customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode);
      setIsLoading(false)
      debugger
      console.log('Post No DE Response', postNoDEResponse);
      if (postNoDEResponse && postNoDEResponse.data.globalCustomerId) {
        //Navigate to Basic Infor Page
        history.push('/basicinfo');
      } else {
        history.push('/IdentityVerify?status=Exception');
      }
    }
  };
  //  Run/No Decision engine ending

  //  Payfon Prefill page continue button function  start
  const handleContinueBtn = async () => {
    setIsLoading(true);
    const findCustomerPayload = {
      firstName: customerDetails.firstName,
      lastName: customerDetails.lastName,
      emailAddress: customerDetails.email,
      dateOfBirth: customerDetails.dateOfBirth,
      phones: [
        {
          phoneNumber: customerDetails.unFormattedPhoneNumber,
        },
      ],
      taxId:
        customerDetails.fullSsn ? customerDetails.fullSsn
          : null,
      addresses: [
        {
          "addressType": "PRIM",
          "addressLine1": customerDetails.addressLine1,
          "addressLine2": customerDetails.addressLine2,
          "city": customerDetails.city,
          "postalCode": customerDetails.zipCode,
          "state": customerDetails.selectedState
        }
      ]
    };
    if (findindCustomerExisitngFlowOpened) {
      setFindCustomerExisitngFlowOpened(false)

      if (sessionStorageValues?.dofFeatureFlag == '1' && customerDetails?.approvalId) {
        setIsLoading(true)
        const request: any = {
          "decisionId": customerDetails?.approvalId
        }
        const getApprovalServiceResponse = await GetApproval(request, customerDetails.authKey ? false : customerDetails.storeCode,
          customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode)
        setIsLoading(false)
        setDecisionEngineDetails(getApprovalServiceResponse)
        const dynamicrouteRes: any = getPendingPriorityDocumentName(getApprovalServiceResponse)
        const encodedDeId: any = new Buffer(customerDetails?.approvalId).toString("base64")
        window.sessionStorage.setItem("dofAuthValues", JSON.stringify({ ...dofAuthValues, encodedApprovalId: encodedDeId }))

        updateNavDetails(setDynamicHeader, getApprovalServiceResponse, { identity: false, residence: false, income: false, reference: false, activeStep: false }, setNavHeader)
        getCustomerDetails(
          customerDetails?.customerId,
          setCVOCustomerDetails,
          landlordResidence,
          setLandlordResidence,
          familyReferenceDetails,
          setFamilyReferenceDetails,
          nonFamilyReferenceDetails,
          setnonFamilyReferenceDetails
        );

        if (dynamicrouteRes) {
          if (dynamicrouteRes == 'identity') {
            history.push(`/dof/verification/${customerDetails?.approvalId}`)
          }
          else {
            history.push(`/${dynamicrouteRes}/${customerDetails?.customerId}/${customerDetails?.approvalId}`)
          }
        }
        else {
          history.push(`/dof/submission/${customerDetails?.customerId}/${customerDetails?.approvalId}`);
        }
      }
      else {
        setIsLoading(false)
        history.push('/basicinfo');
        return
      }
    }

    const existingCustomerResponse: any = await FindCustomer(
      findCustomerPayload,
      customerDetails.authKey ? false : customerDetails.storeCode,
      customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode
    );

    setIsLoading(false)
    let createCustomerResp: any = null;
    createCustomerResp = {
      customerId: customerDetails?.customerId,
      globalCustomerId: customerDetails?.globalCustomerId
    }
    if (
      existingCustomerResponse?.customer &&
      existingCustomerResponse?.customer.globalCustomerId
    ) {
      setIsLoading(true)
      let getCustomerInfoRes: any = {};
      let decryptResponse: any = {};
      let decryptiondataSSN: any;
      let decryptiondataofbirth: any;
      let decryptiondataGovId: any;





      await Promise.all([
        FindCustomer({ GcId: existingCustomerResponse.customer.globalCustomerId },
          customerDetails.authKey ? false : customerDetails.storeCode,
          customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode)

      ]).then((resp) => {
        [getCustomerInfoRes] = resp;
      });
      console.log('getCustomerInfoRes', getCustomerInfoRes)



      const getCustomerDetailsReq = {
        getCustomerDetails: true,
        customerId: getCustomerInfoRes.customerId
      }
      const getCustomerDetailsRes: any = await FindCustomer(getCustomerDetailsReq, customerDetails.authKey ? false : customerDetails.storeCode,
        customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode)



      const payloadForDecrypt: any = { decryptData: {}, encryptKey: crypto.randomBytes(16).toString("hex"), ivKey: crypto.randomBytes(8).toString("hex") }

      if (existingCustomerResponse && existingCustomerResponse?.customer?.dateOfBirthEnc) {
        payloadForDecrypt.decryptData.dateOfBirth = existingCustomerResponse?.customer?.dateOfBirthEnc
      }
      if (existingCustomerResponse && existingCustomerResponse?.customer?.taxIdEnc) {
        payloadForDecrypt.decryptData.taxIdEnc = existingCustomerResponse?.customer?.taxIdEnc
      }
      if (getCustomerDetailsRes && getCustomerDetailsRes?.governmentId) {
        payloadForDecrypt.decryptData.governmentIdEnc = getCustomerDetailsRes?.governmentIdEnc
      }

      decryptResponse = await FindCustomer(payloadForDecrypt, customerDetails.authKey ? false : customerDetails.storeCode,
        customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode),
        console.log('decryptResponse', decryptResponse)
      if (decryptResponse && decryptResponse?.decryptData) {

        if (existingCustomerResponse && existingCustomerResponse?.customer?.dateOfBirthEnc) {

          decryptiondataofbirth = getData(payloadForDecrypt.encryptKey, payloadForDecrypt.ivKey, decryptResponse?.decryptData?.dateOfBirth)


          console.log('decryptiondataofbirth', decryptiondataofbirth);
        }

        if (existingCustomerResponse && existingCustomerResponse?.customer?.taxIdEnc) {
          decryptiondataSSN = getData(payloadForDecrypt.encryptKey, payloadForDecrypt.ivKey, decryptResponse?.decryptData?.taxIdEnc)
          console.log('decryptiondatSSN', decryptiondataofbirth);
        }

        if (getCustomerDetailsRes && getCustomerDetailsRes?.governmentId) {
          decryptiondataGovId = getData(payloadForDecrypt.encryptKey, payloadForDecrypt.ivKey, decryptResponse?.decryptData?.governmentIdEnc)
          console.log('decryptiondatGovId', decryptiondataGovId);
        }

      }
      setIsLoading(false)


      console.log("getCustomerDetailsRes11", getCustomerDetailsRes)


      //  Basic Info prefill data

      customerDetails = {
        ...customerDetails, bestTimeToCall: getCustomerDetailsRes?.phones[0]?.callTimeType, govtIdType: getCustomerDetailsRes?.governmentIdType, govtId: decryptiondataGovId, govtIdState: getCustomerDetailsRes?.governmentIdIssuedState ? getCustomerDetailsRes?.governmentIdIssuedState : '', globalCustomerId: getCustomerInfoRes?.globalCustomerId, customerId: getCustomerInfoRes?.customerId,
        maskedGovId: getCustomerDetailsRes?.governmentId,
        approvalId: existingCustomerResponse?.customer?.approvalId
      }
      setCustomerDetails(customerDetails)

      //  Employer  Info prefill data

      if (getCustomerDetailsRes?.employerReferences) {
        employerDetails = {
          ...employerDetails, companyName: getCustomerDetailsRes?.employerReferences[0]?.employerName, howLong: getCustomerDetailsRes?.employerReferences[0]?.lengthOfEmploymentYears,
          employerPhone: getCustomerDetailsRes?.employerReferences[0]?.employerPhoneNumber, paydayFrequency: getCustomerDetailsRes?.employerReferences[0]?.employerPayschedule, dayPaid: getCustomerDetailsRes?.employerReferences[0]?.daysPaid, takeHomePay: getCustomerDetailsRes?.employerReferences[0]?.takeHomePay, empAddress1: getCustomerDetailsRes?.employerReferences[0]?.addressLine1, empAddress2: getCustomerDetailsRes?.employerReferences[0]?.addressLine2, empZipCode: getCustomerDetailsRes?.employerReferences[0]?.postalCode, empCity: getCustomerDetailsRes?.employerReferences[0]?.city, empState: getCustomerDetailsRes?.employerReferences[0]?.state ? getCustomerDetailsRes?.employerReferences[0]?.state : ''
        }
        setEmployerDetails(employerDetails)
        setFormatMobileNumber(Validator(getCustomerDetailsRes?.employerReferences[0]?.employerPhoneNumber, "phoneNumber")[2])
        await buildDayPaidOptions(getCustomerDetailsRes?.employerReferences[0]?.employerPayschedule)

      }

      //  Residence  prefill data


      if (getCustomerDetailsRes?.landlordReferences) {
        residenceDetails = {
          ...residenceDetails, residenceInfo: 'Rent', rentMoveInDate: getCustomerDetailsRes?.landlordReferences[0]?.moveInDate,
          landlordFirstName: getCustomerDetailsRes?.landlordReferences[0]?.landlordFirstName, landlordLastName: getCustomerDetailsRes?.landlordReferences[0]?.landlordFirstName,
          rentPayment: getCustomerDetailsRes?.landlordReferences[0]?.monthlyRent,
          landlordPhoneNumber: Validator(getCustomerDetailsRes?.landlordReferences[0]?.phoneNumber, 'phoneNumber')[0]
        }
        setFormatedLLPhone(Validator(getCustomerDetailsRes?.landlordReferences[0]?.phoneNumber, 'phoneNumber')[2])
        setIsVerified({ ...isVerified, landLordFirstName: true, landLordLastName: true, landLordPhoneNumber: true })
      }
      else {
        residenceDetails = {
          ...residenceDetails, residenceInfo: 'Own',
          mortgageCompanyName: getCustomerDetailsRes?.mortgageCompanyName,
          ownMortgagePayment: getCustomerDetailsRes?.monthlyMortgagePayment,
          ownMoveInDate: getCustomerDetailsRes?.residenceSince,

        }
      }

      setResidenceDetails(residenceDetails)
      //  Reference Info prefill data
      if (getCustomerDetailsRes?.personalReferences) {
        referenceDetails = {
          ...referenceDetails, refFirstName: getCustomerDetailsRes?.personalReferences[0]?.firstName,
          refLastName: getCustomerDetailsRes?.personalReferences[0]?.lastName,
          refBestTimeToCall: getCustomerDetailsRes?.personalReferences[0]?.bestTimeToCall, relationShipType: getCustomerDetailsRes?.personalReferences[0]?.relationshipType,
          refAddress1: getCustomerDetailsRes?.personalReferences[0]?.addressLine1,
          refAddress2: getCustomerDetailsRes?.personalReferences[0]?.addressLine2,
          refZipCode: getCustomerDetailsRes?.personalReferences[0]?.postalCode,
          refCity: getCustomerDetailsRes?.personalReferences[0]?.city,
          refState: getCustomerDetailsRes?.personalReferences[0]?.state ? getCustomerDetailsRes?.personalReferences[0]?.state : '',
          refPhoneNumber: Validator(getCustomerDetailsRes?.personalReferences[0]?.phoneNumber, 'phoneNumber')[0]
        }
        setReferenceDetails(referenceDetails)
        setFormatedMobileNumber(Validator(getCustomerDetailsRes?.personalReferences[0]?.phoneNumber, 'phoneNumber')[2])

      }

      const clonedCustomerDetails = { ...customerDetails };
      const clonedCustomerCopy: any = { ...existCustomerDetailsCopy };

      clonedCustomerDetails.globalCustomerId =
        existingCustomerResponse.customer.globalCustomerId;
      clonedCustomerDetails.firstName =
        existingCustomerResponse.customer.firstName;
      clonedCustomerDetails.lastName =
        existingCustomerResponse.customer.lastName;
      clonedCustomerDetails.fullSsn =
        decryptiondataSSN
          ? decryptiondataSSN
          : existingCustomerResponse.customer.taxId ? existingCustomerResponse.customer.taxId : '';
      clonedCustomerDetails.dateOfBirth =
        decryptiondataofbirth
          ? decryptiondataofbirth
          : existingCustomerResponse.customer.dateOfBirth ? existingCustomerResponse.customer.dateOfBirth : '';
      clonedCustomerCopy.firstName =
        existingCustomerResponse.customer.firstName;
      clonedCustomerCopy.lastName =
        existingCustomerResponse.customer.lastName;
      clonedCustomerCopy.markedDOB =
        existingCustomerResponse.customer.dateOfBirth;
      clonedCustomerCopy.markedSsn = existingCustomerResponse.customer.taxId
        ? existingCustomerResponse.customer.taxId
        : '';
      clonedCustomerCopy.email = existingCustomerResponse.customer.emailAddress
        ? existingCustomerResponse.customer.emailAddress
        : '';

      if (
        existingCustomerResponse.customer.addresses &&
        existingCustomerResponse.customer.addresses.length > 0
      ) {
        clonedCustomerDetails.addressLine1 =
          existingCustomerResponse.customer.addresses[0].addressLine1;
        clonedCustomerDetails.addressLine2 =
          existingCustomerResponse.customer.addresses[0].addressLine2;
        clonedCustomerDetails.zipCode =
          existingCustomerResponse.customer.addresses[0].postalCode;
        clonedCustomerDetails.selectedState =
          existingCustomerResponse.customer.addresses[0].stateCode;
        clonedCustomerDetails.city =
          existingCustomerResponse.customer.addresses[0].city;

        clonedCustomerCopy.addressLine1 = existingCustomerResponse.customer
          .addresses[0].addressLine1
          ? existingCustomerResponse.customer.addresses[0].addressLine1
          : '';
        clonedCustomerCopy.addressLine2 = existingCustomerResponse.customer
          .addresses[0].addressLine2
          ? existingCustomerResponse.customer.addresses[0].addressLine2
          : ' ';
        clonedCustomerCopy.zipCode = existingCustomerResponse.customer
          .addresses[0].postalCode
          ? existingCustomerResponse.customer.addresses[0].postalCode
          : '';
        clonedCustomerCopy.state = existingCustomerResponse.customer
          .addresses[0].state
          ? existingCustomerResponse.customer.addresses[0].state
          : '';
        clonedCustomerCopy.city = existingCustomerResponse.customer
          .addresses[0].city
          ? existingCustomerResponse.customer.addresses[0].city
          : '';
        clonedCustomerCopy.dateOfBirth =
          decryptiondataofbirth
            ? decryptiondataofbirth
            : existingCustomerResponse.customer.dateOfBirth ? existingCustomerResponse.customer.dateOfBirth : '';
        clonedCustomerCopy.markedDOB = moment((decryptiondataofbirth
          ? decryptiondataofbirth
          : existingCustomerResponse.customer.dateOfBirth ? existingCustomerResponse.customer.dateOfBirth : '').toString(), 'YYYYMMDD').format('MM/DD/').toString() + 'XXXX';
      }
      setCustomerDetails(clonedCustomerDetails);
      setExistCustomerDetailsCopy(clonedCustomerCopy);


      setIsExistCustomerPageOpen(true);

      setIsDOBVerified(true);
      setIsAddressLine1Verified(true);
      setIsZipCodeVerified(true);
      setIsCityVerified(true);
      setIsStateVerified(true);
      setIsDOBDisable(true);
      const currentDate = moment();
      const targetDate = moment(existingCustomerResponse?.customer?.approvalEndDate);
      const differenceInDays = targetDate.diff(currentDate, 'days');
      setCustomerDetails({
        ...customerDetails,
        approvalResponse: existingCustomerResponse?.customer?.approvalStatus == 'Approve' && differenceInDays >= 0 ? '1' : existingCustomerResponse?.customer?.approvalStatus?.toUpperCase() == "MANUAL_INTERVENTION" || existingCustomerResponse?.customer?.approvalStatus?.toUpperCase() == "CONDITIONAL APPROVAL" ? '4' : '2',
        weeklyApprovalAmount: existingCustomerResponse?.customer?.weeklyApprovalAmount,
        totalApprovalAmount: existingCustomerResponse?.customer?.approvalAmount,
      });

      if (!isPayFoneDetails) {
        findindCustomerExisitngFlowOpened = true
        setFindCustomerExisitngFlowOpened(findindCustomerExisitngFlowOpened)
        setIsExistCustomerPageOpen(true)
      }
      else {
        if (sessionStorageValues?.dofFeatureFlag == '1' && existingCustomerResponse?.customer?.approvalId) {
          setIsLoading(true)
          const request: any = {
            "decisionId": existingCustomerResponse?.customer?.approvalId
          }
          const getApprovalServiceResponse = await GetApproval(request, customerDetails.authKey ? false : customerDetails.storeCode,
            customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode)
          setIsLoading(false)
          setDecisionEngineDetails(getApprovalServiceResponse)
          const dynamicrouteRes: any = getPendingPriorityDocumentName(getApprovalServiceResponse)
          const encodedDeId: any = new Buffer(existingCustomerResponse?.customer?.approvalId).toString("base64")
          window.sessionStorage.setItem("dofAuthValues", JSON.stringify({ ...dofAuthValues, encodedApprovalId: encodedDeId, approvalId: existingCustomerResponse?.customer?.approvalId }))

          updateNavDetails(setDynamicHeader, getApprovalServiceResponse, { identity: false, residence: false, income: false, reference: false, activeStep: false }, setNavHeader)
          getCustomerDetails(
            existingCustomerResponse?.customer?.customerId,
            setCVOCustomerDetails,
            landlordResidence,
            setLandlordResidence,
            familyReferenceDetails,
            setFamilyReferenceDetails,
            nonFamilyReferenceDetails,
            setnonFamilyReferenceDetails
          );

          if (dynamicrouteRes) {
            if (dynamicrouteRes == 'identity') {
              history.push(`/dof/verification/${existingCustomerResponse?.customer?.approvalId}`)
            }
            else {
              history.push(`/${dynamicrouteRes}/${existingCustomerResponse?.customer?.customerId}/${existingCustomerResponse?.customer?.approvalId}`)
            }
          }
          else {
            setIsLoading(false)
            history.push(`/dof/submission/${existingCustomerResponse?.customer?.customerId}/${existingCustomerResponse?.customer?.approvalId}`);
          }
        }
        else {
          setIsLoading(false)
          history.push('/basicinfo');
        }
      }

    }



    // Customer not presesnt in the RACDb- Create customer flow
    else if (!isCustomerExist) {
      debugger
      const dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues");
      const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
      setIsLoading(true)
      const customerAddress = {
        addressType: 'PRIM',
        addressLine1: customerDetails.addressLine1,
        addressLine2: customerDetails.addressLine2,
        city: customerDetails.city,
        state: customerDetails.selectedState,
        postalCode: customerDetails.zipCode,
      };
      const customerPhone: any = {
        phoneNumber: customerDetails.mobileNumber?.replace(/[()\s-]/g, ''),
        phoneType: 'CELL',
        primary: 'Y',
        callTimeType: "MORN",
        active: 'Y'
      };
      const RunDePhone: any = {
        phoneNumber: customerDetails.mobileNumber?.replace(/[()\s-]/g, ''),
        phoneType: 'CELL',
        primary: 'Y',
        callTimeType: "MORN",

      };
      const createCustomerPayload: CreateCustomerRequest = {

        firstName: customerDetails?.firstName,
        lastName: customerDetails?.lastName,
        emailAddress: customerDetails?.email,
        dateOfBirth: customerDetails.dateOfBirth,
        addresses: [customerAddress],
        phones: [customerPhone],
        taxId: customerDetails.fullSsn
          ? customerDetails.fullSsn
          : null,
        preferredLanguage: "ENG",
        storeNumber: customerDetails.storeCode
      };
      const createCustomerResponse: CreateCustomerResponse =
        await CreateCustomer(createCustomerPayload, customerDetails.authKey ? false : customerDetails.storeCode,
          customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode);

      setIsLoading(false)
      debugger
      createCustomerResp = {
        ...createCustomerResponse,
        emailAddress: customerDetails?.email,
        originator: sessionStorageValues?.clientId ? atob(sessionStorageValues?.clientId) : '',
      };
      customerDetails = { ...customerDetails, customerId: createCustomerResponse.customerId, globalCustomerId: createCustomerResponse.globalCustomerId }
      setCustomerDetails(customerDetails)
      console.log("createCustomerResponse0", createCustomerResponse)
      validateCustomer(customerAddress, RunDePhone, createCustomerResp);
    }
  };

  /* Payfone prefill page continue click ending */

  // Function for Edit button click

  const handleEditInfobtn = () => {
    setIsExistCustomerPageOpen(false);

  };
  // Function for Previous button click

  const handlePreviousBtn = () => {
    if (isCustomerExist) {
      setIsExistCustomerPageOpen(true);
    } else {
      history.push('UserInfo');
    }
  };
  // Function for Existing customer Previous button click

  const handleExisCustPreviousBtn = () => {
    history.push('UserInfo');
  };
  const buildDateFormat2 = (date: any, type?: 'V') => {
    const d = date == '' ? new Date() : date;

    console.log(moment(d).format('MM/DD/YYYY'), 'date');
    return moment(d).format('MM/DD/YYYY');
  };

  // New React Date pciker

  const datePickerRef = useRef<DatePicker | null>(null);

  // Calculate the max date to be 18 years before today
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
      setCustomerDetails({ ...customerDetails, dateOfBirth: formattedDate })
      setIsDOBVerified(true);
    } else {
      setCustomerDetails({ ...customerDetails, dateOfBirth: '' })
    }
  };

  const formatDisplayDate = (date: string | null) => {
    if (!date) return 'mm/dd/yyyy';
    const [year, month, day] = date.split('-');
    return `${month}/${day}/****`;
  };

  return (
    <Grid>
      <DofHeaders />
      {!isLoading ? (
        // Is Customer Exist
        isExistCustomerPageOpen ? (
          <Grid container className={dofClassName.topMargin}>
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.floatCenter}`}
            >
              <Grid
                item
                lg={6}
                md={8}
                sm={10}
                xs={11}
                className={`${dofClassName.marginLeftRight}`}
              >
                <Typography className={`${dofClassName.existCustTitle}`}>
                  {' '}
                  Please review your information and confirm it is accurate:{' '}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.floatCenter}`}
            >
              <Grid container lg={6} md={8} sm={10} xs={11}>
                <Card className={`${dofClassName.cardContainer}`}>
                  <Grid item className={`${dofClassName.basicInfoCardStyle}`}>
                    <Typography className={dofClassName.fontWeightForName} >
                      {existCustomerDetailsCopy.firstName}{' '}
                      {existCustomerDetailsCopy.lastName}
                    </Typography>
                    <Typography className={`${dofClassName.existCustHeadPayfone}`} style={{ fontSize: 'medium' }}>
                      Current Address
                    </Typography>
                    <Typography
                      className={`${dofClassName.existCustTexts}`}
                      style={{
                        fontFamily: 'OpenSans-semibold',
                        fontSize: '12px', marginTop: '7px'
                      }}
                    >
                      {existCustomerDetailsCopy.addressLine1}{' '}
                      {existCustomerDetailsCopy.addressLine2}
                    </Typography>
                    <Typography className={`${dofClassName.existCustTexts}`}
                      style={{ fontFamily: 'OpenSans-semibold', fontSize: '13px' }}
                    >
                      {existCustomerDetailsCopy.city}{' '}
                      {existCustomerDetailsCopy.state}{' '}
                      {existCustomerDetailsCopy.zipCode}
                    </Typography>
                    <Typography className={`${dofClassName.existCustHeadPayfone}`} style={{ fontSize: 'medium' }}>
                      Date of Birth
                    </Typography>
                    <Typography className={`${dofClassName.existCustTexts}`}
                      style={{ fontFamily: 'OpenSans-semibold', fontSize: '13px' }}
                    >
                      {`${monthIndexes[Number(existCustomerDetailsCopy.markedDOB?.split('/')[0])]}  ${existCustomerDetailsCopy.markedDOB?.split('/')[1]}, ****`}
                    </Typography>
                    <Typography className={`${dofClassName.existCustHeadPayfone}`} style={{ fontSize: 'medium' }}>
                      Email Address
                    </Typography>
                    <Typography className={`${dofClassName.existCustTexts}`}
                      style={{ fontFamily: 'OpenSans-semibold', fontSize: '13px' }}
                    >
                      {existCustomerDetailsCopy.email ? existCustomerDetailsCopy.email : customerDetails.email ? customerDetails.email : ''}
                    </Typography>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.floatCenter} ${dofClassName.btnDivMargin}`}
            >
              <Grid
                container
                lg={6}
                md={8}
                sm={10}
                xs={11}
                className={`${dofClassName.spaceBetween}`}
              >
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={4}
                  xs={4}
                  className={`${dofClassName.textAlignLeft}`}
                >
                  <RACButton
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      handleExisCustPreviousBtn();
                    }}
                  >
                    Previous
                  </RACButton>
                </Grid>
                <Grid item className={`${dofClassName.textAlignRight}`}
                  style={{
                    marginRight: '15px'
                  }}
                >
                  <RACButton
                    onClick={() => {
                      handleEditInfobtn();
                    }}
                    color="primary"
                    variant="text"
                    style={{
                      border: 'none',
                      textDecoration: 'underline'
                    }}
                  >
                    EDIT INFO
                  </RACButton>

                  <RACButton
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      handleContinueBtn();
                    }}
                  >
                    Continue
                  </RACButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          // Customer Not Exist
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={`${dofClassName.floatCenter}`}
          >
            <Grid container lg={6} md={8} sm={10} xs={10}>
              <Grid
                container
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={`${dofClassName.displayFlex} ${dofClassName.floatCenter}`}
              >
                {/* SSN  */}
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
                >
                  <RACTextbox
                    inputlabel="SSN"
                    name="SSN"

                    value={
                      customerDetails?.fullSsn !==
                        undefined &&
                        customerDetails?.fullSsn !== null &&
                        customerDetails?.fullSsn !== ''
                        ? customerDetails?.fullSsn.length >
                          4 && isGovtNumOnFocus !== true
                          ?
                          customerDetails?.fullSsn
                            .substring(
                              0,
                              customerDetails?.fullSsn
                                .length - 4
                            )
                            .replace(/[A-Za-z0-9.,-]/g, '*') +
                          customerDetails?.fullSsn.substring(
                            customerDetails?.fullSsn
                              .length - 4
                          )
                          : customerDetails?.fullSsn
                        : ''
                    }
                    maxlength={9}
                    disabled={false}
                    type="text"
                    OnChange={(e: any) => handleInput(e)}
                    onFocus={() => setisGovtNumOnFocus(true)}
                    Onblur={() => setisGovtNumOnFocus(false)}
                    inputLabelClassname={dofClassName.marginBottomforInput}
                    className={`${dofClassName.inputBoxHeight}`}
                  />
                </Grid>

                {/* Name */}
                <Grid
                  container
                  spacing={2}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`${dofClassName.displayFlex} ${dofClassName.spaceBetween} ${dofClassName.marginInputFields}`}
                >
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <RACTextbox
                      inputlabel="First Name"
                      name="FirstName"
                      required={true}
                      value={customerDetails.firstName}
                      maxlength={30}
                      disabled={true}
                      type="text"
                      OnChange={(e: any) => handleInput(e)}
                      inputLabelClassname={dofClassName.marginBottomforInput}
                      className={`${dofClassName.inputBoxHeight}`}
                      inputTextBoxClassname={dofClassName.inputBoxHeight}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <RACTextbox
                      inputlabel="Last Name"
                      name="LastName"
                      required={true}
                      value={customerDetails.lastName}
                      maxlength={30}
                      disabled={true}
                      type="text"
                      OnChange={(e: any) => handleInput(e)}
                      inputLabelClassname={dofClassName.marginBottomforInput}
                      className={`${dofClassName.inputBoxHeight}`}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
                >
                  <RACTextbox
                    inputlabel="Email"
                    required={true}
                    value={customerDetails.email}
                    maxlength={50}
                    disabled={true}
                    inputLabelClassname={dofClassName.marginBottomforInput}
                    className={`${dofClassName.inputBoxHeight}`}
                    inputTextBoxClassname={dofClassName.inputBoxHeight}
                  />
                </Grid>

                <Grid
                  container
                  spacing={2}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`${dofClassName.displayFlex} ${dofClassName.spaceBetween} ${dofClassName.marginInputFields}`}
                >
                  <Grid item lg={6} md={6} sm={6} xs={6} style={{ position: 'relative' }}>

                    {/* <span style={{
                      position: 'absolute',
                      marginTop: '44px',
                      backgroundColor: 'white',
                      left: '17px',
                      zIndex: 99999,
                      paddingRight: '8px'

                    }}

                    >
                      {customerDetails.dateOfBirth !==
                        undefined &&
                        customerDetails.dateOfBirth !==
                        null &&
                        customerDetails.dateOfBirth !== ''
                        ? buildDateFormat2(
                          customerDetails.dateOfBirth
                        ).substring(0, 6) + '****'
                        : 'mm/dd/yyyy'}
                    </span>

                    <div className={dofClassName.datePickerClass}>

                      <RACDatePicker
                        label="Date of Birth"
                        name="DOB"
                        required={true}
                        inputProps={{
                          min: moment().subtract(110, 'years').format('YYYY-MM-DD'),
                          max: moment.utc().subtract(18, 'years').format('YYYY-MM-DD')
                        }}

                        disabled={false}
                        value={customerDetails.dateOfBirth}
                        onChange={(e: any) => handleInput(e, 'dob')}
                        classes={{
                          input: dofClassName.inputBoxHeight,
                          label: dofClassName.textAlignLeft,
                        }}
                      />
                    </div> */}

                    <div>

                      <label className={dofClassName.labelStyle}>Date of Birth <span style={{ color: 'red' }}>*</span></label>
                      <div className={dofClassName.datePickerWrapperStyle}>
                        <div
                          className={dofClassName.inputContainerStyle}
                          onClick={() => datePickerRef.current?.setOpen(true)} // Open the date picker on click
                        >
                          {formatDisplayDate(customerDetails?.dateOfBirth)}
                        </div>
                        <DatePicker
                          ref={datePickerRef}
                          selected={customerDetails?.dateOfBirth ? new Date(customerDetails?.dateOfBirth) : null}
                          onChange={handleDateChange}
                          maxDate={maxDate}
                          dateFormat="MM/dd/yyyy"
                          customInput={<div style={{ display: 'none' }} />} // Hidden custom input
                          filterDate={(date) => date <= maxDate} // Disable dates greater than maxDate
                          calendarContainer={({ children }) => (
                            <div className={dofClassName.calendarStyle}>
                              {children}
                            </div>
                          )}
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                                zIndex: 2, // Ensure the header is above the dropdown
                              }}
                            >
                              <button
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                                style={{
                                  cursor: "pointer",
                                  background: "none",
                                  border: "none",
                                  padding: 0,
                                  outline: "none",
                                }}
                              >
                                {"<"}
                              </button>
                              <div style={{ position: "relative", maxHeight: '36px' }}>
                                <select
                                  value={date.getFullYear()}
                                  onChange={({ target: { value } }) => changeYear(parseInt(value))}
                                  style={{
                                    margin: "0 10px",
                                    cursor: "pointer",
                                    background: "none",
                                    border: "1px solid #ccc",
                                    borderRadius: '4px',
                                    padding: '5px',
                                    maxHeight: '36px', // Ensures the dropdown stays inside the date picker
                                    outline: "none",
                                    overflowY: 'auto', // Allows scrolling within the dropdown
                                    zIndex: 3, // Ensure the dropdown is above the date picker
                                    position: "relative",
                                  }}
                                >
                                  {Array.from({ length: 100 }, (_, i) => (
                                    <option key={i} value={maxDate.getFullYear() - i}>
                                      {maxDate.getFullYear() - i}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div style={{ position: "relative" }}>
                                <select
                                  value={date.getMonth()}
                                  onChange={({ target: { value } }) => changeMonth(parseInt(value))}
                                  style={{
                                    margin: "0 10px",
                                    cursor: "pointer",
                                    background: "none",
                                    border: "1px solid #ccc",
                                    borderRadius: '4px',
                                    padding: '5px',
                                    maxHeight: '36px', // Ensures the dropdown stays inside the date picker
                                    outline: "none",
                                    overflowY: 'auto', // Allows scrolling within the dropdown
                                    zIndex: 3, // Ensure the dropdown is above the date picker
                                    position: "relative",
                                  }}
                                >
                                  {Array.from({ length: 12 }, (_, i) => (
                                    <option key={i} value={i}>
                                      {new Date(0, i).toLocaleString('default', { month: 'long' })}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <button
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                                style={{
                                  cursor: "pointer",
                                  background: "none",
                                  border: "none",
                                  padding: 0,
                                  outline: "none",
                                }}
                              >
                                {">"}
                              </button>
                            </div>
                          )}
                        />
                      </div>
                    </div>



                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <RACTextbox
                      inputlabel="Address Line 1"
                      name="Address1"
                      required={true}
                      value={customerDetails.addressLine1}
                      maxlength={30}
                      disabled={false}
                      type="text"
                      OnChange={(e: any) => handleInput(e)}
                      inputLabelClassname={dofClassName.marginBottomforInput}
                      className={`${dofClassName.inputBoxHeight}`}
                    />
                  </Grid>
                </Grid>

                {/* Address Line 2 */}
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields} ${dofClassName.marginInputFields}`}
                >
                  <RACTextbox
                    inputlabel="Address Line 2"
                    name="Address2"
                    required={false}
                    value={customerDetails.addressLine2}
                    maxlength={30}
                    disabled={false}
                    type="text"
                    OnChange={(e: any) => handleInput(e)}
                    inputLabelClassname={dofClassName.marginBottomforInput}
                    className={`${dofClassName.inputBoxHeight}`}
                    inputTextBoxClassname={dofClassName.inputBoxHeight}
                  />
                </Grid>

                <Grid
                  container
                  spacing={2}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`${dofClassName.displayFlex} ${dofClassName.spaceBetween} ${dofClassName.marginInputFields}`}
                >
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <RACTextbox
                      inputlabel="Zip Code"
                      name="ZIPCode"
                      required={true}
                      value={customerDetails.zipCode}
                      maxlength={10}
                      disabled={false}
                      type="text"
                      OnChange={(e: any) => handleInput(e)}
                      inputLabelClassname={dofClassName.marginBottomforInput}
                      className={`${dofClassName.inputBoxHeight}`}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <RACTextbox
                      inputlabel="City"
                      name="City"
                      required={true}
                      value={customerDetails.city}
                      maxlength={30}
                      disabled={false}
                      type="text"
                      OnChange={(e: any) => handleInput(e)}
                      inputLabelClassname={dofClassName.marginBottomforInput}
                      className={`${dofClassName.inputBoxHeight}`}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <div className={dofClassName.inputBoxHeightForSelect}>
                      <RACSelect
                        inputLabel="State"
                        required={true}
                        defaultValue={customerDetails.selectedState}
                        options={stateOptions}
                        onChange={(e: any) => {
                          handleInput(e, 'state');
                        }}
                        inputLabelClassName={dofClassName.marginBottomforInput}
                        classes={{
                          inputLabel: dofClassName.textAlignLeft
                        }}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`${dofClassName.topMargin} ${dofClassName.spaceBetween}`}
                >
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className={dofClassName.textAlignLeft}
                  >
                    <RACButton
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        handlePreviousBtn();
                      }}
                    >
                      Previous
                    </RACButton>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className={dofClassName.textAlignRight}
                  >
                    <div className={dofClassName.isDisabledColor}>
                      <RACButton
                        onClick={() => {
                          handleContinueBtn();
                        }}
                        disabled={
                          !(
                            isDOBVerified &&
                            isAddressLine1Verified &&
                            isStateVerified &&
                            isCityVerified &&
                            isZIPCodeVerified && (
                              customerDetails.fullSsn ? customerDetails.fullSsn.length > 8 ? true : false : true
                            )
                          )

                        }
                        color="primary"
                        variant="contained"
                      >
                        Continue
                      </RACButton>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              {/* </Card> */}
            </Grid>
          </Grid>
        )
      ) : (
        ''
      )}
      {isLoading ? <LoaderPopup message={'Please wait...'} /> : ''}
    </Grid>
  );
}
