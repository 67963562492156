/* eslint-disable  */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Grid,
  LinearProgress,
  Link,
  List,
  Paper,
  RACButton,
  RACModalCard,
  Typography,
} from "@rentacenter/racstrap";
import React, { useContext, useEffect, useState } from "react";
import { DigitalOrderFormContext } from "../Context/DigitialOrderFormContext";
import { ReactComponent as CameraIcon } from "./../../../images/cameraIcon.svg";
import { ReactComponent as UploadIcon } from "./../../../images/uploadImage.svg";
import { ReactComponent as ImageIcon } from "./../../../images/image-icon.svg";
import { ReactComponent as PdfIcon } from "./../../../images/pdf-icon.svg";
import { ReactComponent as DeleteIcon } from "./../../../images/delete-icon.svg";
import { VerificationStyles } from "../../../styles/verificationStyles";
import DOFHeader from "./DOFHeader";
import {
  convertImageToPDF,
  convertPDFToBase64,
  getBackNavigator,
  getNextNavigator,
  getNoteText,
  getTabsHeading,
  handleFile,
  updateCustomerApproval,
  uploadDocuments,
} from "../Common/HelperFunctions";
import {
  APPLICATION_PDF,
  EMPLOYMENT_TAB_DOCUMENTS_LIST,
  SUPPORTED_FILE_TYPE,
} from "../../../constants/constants";
import { BooleanRepresentation } from "../../../constants/enums";
import { useHistory, useParams } from "react-router-dom";
import SomethingWentWrongPopUp from "../Common/SomethingWentWrong";
import ScreenLoader from "../Common/ScreenLoader";
import jsPDF from "jspdf";
import MessagePopup from "../Common/MessagePopup";
import FileToPDFViewer from "../Common/FileToPdfViewer";
import flipCameraIcon from './../../../images/camera-flip.svg';
import closeIconImage from './../../../images/close-icon.svg';
import DocumentCapture from "../Common/OpenCamera";
import ScrollToTop from "../Common/ScrollToTop";

export const IncomeTab = () => {
  const history = useHistory();
  const {
    businessFlow,
    setIncomeDoc,
    incomeDoc,
    showIncomeVerfDocument,
    setShowIncomeVerfDocument,
    incomeFileType,
    setIncomeFileType,
    incomeFileSize,
    setIncomeFileSize,
    dynamicHeader,
    setShowIncomeDetails,
    setIncomeDocCopy,
    setIncomeFileTypeCopy,
    setIncomeFileSizeCopy
  } = useContext(DigitalOrderFormContext);
  const classes: any = VerificationStyles();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [base64Files, setBase64Files] = useState<string[]>([]);
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const [showSecondaryDoc, setShowSecondaryDoc] = useState<boolean>(false);
  const { customerId, approvalId } = useParams<any>();
  const [error, setError] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const queryParams = new URLSearchParams(window.location.search);
  const source = queryParams.get("source");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [messagePopup, setMessagePopup] = useState<boolean>(false);
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const dofAuthSessionValue: any =
    window.sessionStorage.getItem("dofAuthValues");
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);

  useEffect(() => {
    // Call sendHeightToParent initially to send the height on mount
    sendHeightToParent();
    // Add an event listener for window resizing
    const handleResize = () => {
      sendHeightToParent();
    };
    window.addEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader]);

  // Adding the Google Analytics tags
  useEffect(() => {

    window.dataLayer?.push({
      'event': 'Loaded the DOF Income Verification Page',
      'storeNo': sessionStorageValues?.storeNumber,
      'approvalId': sessionStorageValues?.approvalId,
      'currentScreen': 'DOF Income Verification Screen',
      'previousScreen': 'DOF Idenity Verification Screen',
    });
  }, []);

  useEffect(() => {
    if (!incomeDoc) {
      setShowIncomeVerfDocument(false)
    }
    else if (incomeDoc) {
      setShowIncomeVerfDocument(true)
    }
  }, [incomeDoc])

  const sendHeightToParent = () => {
    if (!loader) {
      const iframeHeight = document.body.scrollHeight; // Get the current height of the iframe content
      const iframeWidth = document.body.scrollWidth;
      window.parent.postMessage(
        {
          message: "racpad-styling",
          minHeight: iframeHeight < 640 ? 640 : iframeHeight,
          currentHeight: iframeHeight,
          iframeWidth: iframeWidth,
          verificationProcessCompleted: false
        },
        "*"
      );
    }
  };

  // const openCamera = async () => {
  //   try {
  //     let currentDeviceId: any = null;
  //     let currentStream: any = null;
  //     // Detect if the user is on a mobile device by checking the screen size and touch events
  //     const isMobile =
  //       window.matchMedia("(max-width: 768px)").matches ||
  //       /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

  //     // List all media devices (microphones, cameras, etc.)
  //     const devices = await navigator.mediaDevices.enumerateDevices();

  //     // Find all video devices (cameras)
  //     const videoDevices = devices.filter(
  //       (device) => device.kind === "videoinput"
  //     );

  //     console.log('video devices', videoDevices);

  //     // Identify the front (user) and rear (environment) cameras
  //     const frontCamera: any = videoDevices.find((device) =>
  //       device.label.toLowerCase().includes("front")
  //     );
  //     const rearCamera: any = videoDevices.find((device) =>
  //       device.label.toLowerCase().includes("back")
  //     );

  //     console.log('Front and rear devices', frontCamera, rearCamera);

  //     // Get the first video device (default camera, typically front camera)
  //     // const defaultDevice = videoDevices[0];
  //     currentDeviceId = frontCamera.deviceId;

  //     // Start the video stream using the selected camera device
  //     const stream = await navigator.mediaDevices.getUserMedia({
  //       video: { deviceId: frontCamera.deviceId },
  //     });

  //     currentStream = stream; // Store the stream for future use
  //     const videoElement = document.createElement("video");
  //     videoElement.srcObject = stream;
  //     videoElement.play();

  //     const canvas = document.createElement("canvas");
  //     const context = canvas.getContext("2d");

  //     // Create a text message element
  //     const messageOverlay = document.createElement("div");
  //     messageOverlay.innerText = "Capture the document within the red area";
  //     messageOverlay.style.position = "absolute";
  //     messageOverlay.style.top = "7.8%"; // Adjust this to position the message
  //     messageOverlay.style.left = "50%";
  //     messageOverlay.style.transform = "translateX(-50%)"; // Center horizontally
  //     messageOverlay.style.fontSize = "14px"; // Font size for visibility
  //     messageOverlay.style.fontWeight = "bold";
  //     messageOverlay.style.color = "#fff"; // White text color
  //     messageOverlay.style.textAlign = "center"; // Center the text
  //     messageOverlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)"; // Semi-transparent background for better readability
  //     messageOverlay.style.padding = "10px"; // Some padding for text clarity
  //     messageOverlay.style.borderRadius = "5px";

  //     const videoContainer = document.createElement("div");
  //     videoContainer.style.position = "fixed";
  //     videoContainer.style.top = "0";
  //     videoContainer.style.left = "0";
  //     videoContainer.style.width = "100%";
  //     videoContainer.style.height = "100%";
  //     videoContainer.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
  //     videoContainer.style.display = "flex";
  //     videoContainer.style.flexDirection = "column";
  //     videoContainer.style.justifyContent = "center";
  //     videoContainer.style.alignItems = "center";
  //     videoContainer.style.zIndex = "1001";

  //     // Square overlay style
  //     const squareOverlay = document.createElement("div");
  //     squareOverlay.style.position = "absolute";
  //     squareOverlay.style.border = "2px solid #ff0000"; // Red border for visibility
  //     squareOverlay.style.width = "300px"; // Size of the crop area (adjust as needed)
  //     squareOverlay.style.height = "350px"; // Same as above
  //     squareOverlay.style.top = "47%";
  //     squareOverlay.style.left = "50%";
  //     squareOverlay.style.transform = "translate(-50%, -50%)"; // Center the square
  //     squareOverlay.style.zIndex = "1002";

  //     // Create the camera flip icon only for mobile devices
  //     const flipIcon = document.createElement("img");
  //     flipIcon.src = flipCameraIcon; // Replace with your flip camera icon URL
  //     flipIcon.style.position = "absolute";
  //     flipIcon.style.top = "12%";
  //     flipIcon.style.left = "90%";
  //     flipIcon.style.transform = "translateX(-50%)"; // Center horizontally
  //     flipIcon.style.cursor = "pointer";
  //     flipIcon.style.width = "50px"; // Adjust size as needed
  //     flipIcon.style.height = "50px"; // Adjust size as needed
  //     flipIcon.style.background = "rgba(0, 0, 0, 0.5)";
  //     flipIcon.style.borderRadius = "50%";
  //     flipIcon.style.padding = "5px";
  //     flipIcon.style.border = "1px solid #ffffff";

  //     flipIcon.onclick = async () => {
  //       // Stop the current stream tracks before switching the camera
  //       currentStream.getTracks().forEach((track: any) => track?.stop());

  //       // // Switch to the next camera (front/back)
  //       // const currentIndex = videoDevices.findIndex(
  //       //   (device) => device.deviceId === currentDeviceId
  //       // );
  //       // const nextDeviceIndex = (currentIndex + 1) % videoDevices.length;

  //       const nextDeviceId = frontCamera.deviceId == currentDeviceId ? rearCamera.deviceId : frontCamera.deviceId;

  //       if (nextDeviceId) {
  //         // Start the new stream with the selected deviceId
  //         const newStream = await navigator.mediaDevices.getUserMedia({
  //           video: { deviceId: nextDeviceId },
  //         });

  //         // Reset the video stream and update the source
  //         videoElement.srcObject = newStream;
  //         videoElement.play();
  //         currentStream = newStream;
  //         currentDeviceId = nextDeviceId;
  //       }
  //     };

  //     // Create the close icon to close the document capture
  //     const closeIcon = document.createElement("img");
  //     closeIcon.src = closeIconImage; // Replace with your close icon URL
  //     closeIcon.style.position = "absolute";
  //     closeIcon.style.top = "12%";
  //     closeIcon.style.left = "3%";
  //     closeIcon.style.cursor = "pointer";
  //     closeIcon.style.width = "50px"; // Adjust size as needed
  //     closeIcon.style.height = "50px"; // Adjust size as needed
  //     closeIcon.style.background = "rgba(0, 0, 0, 0.5)";
  //     closeIcon.style.borderRadius = "50%";
  //     closeIcon.style.padding = "5px";
  //     closeIcon.style.border = "1px solid #ffffff";

  //     closeIcon.onclick = () => {
  //       // Close the document capture view by removing the video container
  //       document.body.removeChild(videoContainer);
  //     };

  //     // // Check if the device is mobile (you can modify this condition based on your needs)
  //     // const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(
  //     //   navigator.userAgent
  //     // );

  //     // // const menuContainer = document.createElement('div');
  //     // // menuContainer.style.position = 'absolute';
  //     // // menuContainer.style.top = "42%";
  //     // // menuContainer.style.left = "50%";
  //     // // menuContainer.style.width = "300px";
  //     // // menuContainer.style.height = "350px";
  //     // // menuContainer.style.display = "flex";
  //     // // menuContainer.style.justifyContent = "space-between";

  //     // // Create the camera flip icon only for mobile devices
  //     // const flipIcon = document.createElement("img");
  //     // flipIcon.src = flipCameraIcon; // Replace with your flip camera icon URL
  //     // flipIcon.style.position = "absolute";
  //     // flipIcon.style.top = "12%";
  //     // flipIcon.style.left = "90%";
  //     // flipIcon.style.transform = "translateX(-50%)"; // Center horizontally
  //     // flipIcon.style.cursor = "pointer";
  //     // flipIcon.style.width = "50px"; // Adjust size as needed
  //     // flipIcon.style.height = "50px"; // Adjust size as needed
  //     // flipIcon.style.background = "rgba(0, 0, 0, 0.5)";
  //     // flipIcon.style.borderRadius = "50%";
  //     // flipIcon.style.padding = "5px";
  //     // flipIcon.style.border = "1px solid #ffffff";

  //     // flipIcon.onclick = async () => {
  //     //   // Find the next video device in the list
  //     //   const currentIndex = videoDevices.findIndex((device) => device.deviceId === currentDeviceId);
  //     //   const nextDeviceIndex = (currentIndex + 1) % videoDevices.length; // Cycle to the next device
  //     //   const nextDeviceId = videoDevices[nextDeviceIndex].deviceId;

  //     //   if (nextDeviceId) {
  //     //     // Stop current stream tracks
  //     //     currentStream.getTracks().forEach((track: any) => track.stop());

  //     //     // Start the new stream with the selected deviceId
  //     //     const newStream = await navigator.mediaDevices.getUserMedia({
  //     //       video: { deviceId: nextDeviceId },
  //     //     });

  //     //     // Set the new stream to the video element
  //     //     videoElement.srcObject = newStream;
  //     //     videoElement.play();
  //     //     currentStream = newStream;
  //     //     currentDeviceId = nextDeviceId; // Update the current device ID
  //     //   }
  //     // };

  //     // // Create the close icon to close the document capture
  //     // const closeIcon = document.createElement("img");
  //     // closeIcon.src = closeIconImage; // Replace with your close icon URL
  //     // closeIcon.style.position = "absolute";
  //     // closeIcon.style.top = "12%";
  //     // closeIcon.style.left = "3%";
  //     // closeIcon.style.cursor = "pointer";
  //     // closeIcon.style.width = "50px"; // Adjust size as needed
  //     // closeIcon.style.height = "50px"; // Adjust size as needed
  //     // closeIcon.style.background = "rgba(0, 0, 0, 0.5)";
  //     // closeIcon.style.borderRadius = "50%";
  //     // closeIcon.style.padding = "5px";
  //     // closeIcon.style.border = "1px solid #ffffff";

  //     // closeIcon.onclick = () => {
  //     //   // Close the document capture view by removing the video container
  //     //   document.body.removeChild(videoContainer);
  //     // };

  //     // menuContainer.appendChild(closeIcon);
  //     // if(isMobile){
  //     //   menuContainer.appendChild(flipIcon);
  //     // }

  //     const captureButton = document.createElement("button");
  //     captureButton.innerText = "Capture Document";
  //     captureButton.style.padding = "10px 20px";
  //     captureButton.style.width = "250px";
  //     captureButton.style.marginTop = "10px";
  //     captureButton.style.color = "#fff";
  //     captureButton.style.backgroundColor = "#0f99d6";
  //     captureButton.style.border = "none";
  //     captureButton.style.cursor = "pointer";
  //     captureButton.style.fontSize = "14px";
  //     captureButton.style.borderRadius = "5px";
  //     captureButton.style.zIndex = "1002";

  //     captureButton.onclick = async () => {
  //       // Get the position and size of the square overlay relative to the viewport
  //       const squareBounds = squareOverlay.getBoundingClientRect();

  //       // Get the position and size of the video element
  //       const videoBounds = videoElement.getBoundingClientRect();

  //       // Calculate the offset of the squareOverlay within the video element (considering scaling)
  //       const offsetX = squareBounds.left - videoBounds.left; // Adjust X based on video position
  //       const offsetY = squareBounds.top - videoBounds.top; // Adjust Y based on video position

  //       // Set the canvas size to match the dimensions of the square overlay
  //       canvas.width = squareBounds.width;
  //       canvas.height = squareBounds.height;

  //       // Capture the part of the video inside the square
  //       context?.drawImage(
  //         videoElement,
  //         offsetX, // Start at the adjusted X position inside the video element
  //         offsetY, // Start at the adjusted Y position inside the video element
  //         squareBounds.width, // Width of the square (same as canvas)
  //         squareBounds.height, // Height of the square (same as canvas)
  //         0,
  //         0, // Draw to the canvas starting at (0, 0)
  //         squareBounds.width, // Canvas width
  //         squareBounds.height // Canvas height
  //       );

  //       // Convert the captured part to Base64
  //       const imageData = canvas.toDataURL("image/png");

  //       // Optionally, show the captured image to the user for confirmation
  //       const previewImage = document.createElement("img");
  //       previewImage.src = imageData;
  //       previewImage.style.maxWidth = "80%"; // Style the preview image
  //       previewImage.style.maxHeight = "80%";

  //       // Append the image preview to the body (or a modal if you prefer)
  //       // document.body.appendChild(previewImage);

  //       // Stop the camera and hide the video container (optional)
  //       // stream.getTracks().forEach((track) => track.stop()); // Uncomment if you want to stop the camera

  //       // Show the cropped image for confirmation
  //       const confirmationContainer = document.createElement("div");
  //       confirmationContainer.style.position = "fixed";
  //       confirmationContainer.style.top = "0";
  //       confirmationContainer.style.left = "0";
  //       confirmationContainer.style.width = "100%";
  //       confirmationContainer.style.height = "100%";
  //       confirmationContainer.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
  //       confirmationContainer.style.display = "flex";
  //       confirmationContainer.style.flexDirection = "column";
  //       confirmationContainer.style.alignItems = "center";
  //       confirmationContainer.style.justifyContent = "center";
  //       confirmationContainer.style.zIndex = "1001";

  //       const imagePreview = document.createElement("img");
  //       imagePreview.src = imageData;
  //       imagePreview.style.maxWidth = "80%";
  //       imagePreview.style.maxHeight = "80%";
  //       imagePreview.style.marginBottom = "15px";

  //       const confirmButton = document.createElement("button");
  //       confirmButton.innerText = "Confirm";
  //       confirmButton.style.width = "100%";
  //       confirmButton.style.padding = "10px 20px";
  //       confirmButton.style.color = "#fff";
  //       confirmButton.style.backgroundColor = "#0f99d6";
  //       confirmButton.style.border = "none";
  //       confirmButton.style.cursor = "pointer";
  //       confirmButton.style.fontSize = "14px";
  //       confirmButton.style.borderRadius = "5px";

  //       const retakeButton = document.createElement("button");
  //       retakeButton.innerText = "Retake";
  //       retakeButton.style.width = "100%";
  //       retakeButton.style.padding = "10px 20px";
  //       retakeButton.style.marginTop = "10px";
  //       retakeButton.style.color = "#0f99d6";
  //       retakeButton.style.backgroundColor = "#fff";
  //       retakeButton.style.border = "none";
  //       retakeButton.style.cursor = "pointer";
  //       retakeButton.style.fontSize = "14px";
  //       retakeButton.style.borderRadius = "5px";

  //       confirmButton.onclick = async () => {
  //         // // Convert captured image to PDF and Base64 (this function should be defined)
  //         // const pdfBase64: any = await convertImageToPdfBase64(imageData);
  //         // console.log("PDF Base64:", pdfBase64);

  //         // // Store Base64 in state variable
  //         // const imageB64String: any = await convertImageToPDF(pdfBase64);
  //         // const base64Pdf: any = await convertPDFToBase64(imageB64String);
  //         // console.log('Final Base64', base64Pdf);

  //         // Stop camera and close the popup
  //         stream.getTracks().forEach((track) => track.stop());
  //         document.body.removeChild(confirmationContainer);

  //         // Create a new PDF document
  //         const pdf = new jsPDF();

  //         // Get image dimensions
  //         const imgWidth = imagePreview.width; // Image width in pixels
  //         const imgHeight = imagePreview.height; // Image height in pixels

  //         // Convert the Base64 image to PDF
  //         pdf.addImage(imageData, "PNG", 10, 10, imgWidth, imgHeight); // Adjust margins (10, 10) as needed

  //         // Save the PDF (optionally, you can give a name)
  //         const pdfBlob: any = pdf.output("blob"); // Get the PDF as a Blob
  //         console.log("PDF Blob", pdfBlob);
  //         const base64Pdf: any = await convertPDFToBase64(pdfBlob);
  //         console.log("base64Pdf", base64Pdf);
  //         setImageBase64(pdfBlob);
  //         setIncomeDoc(pdfBlob);

  //         // // Optionally, download the PDF
  //         // pdf.save("captured-image.pdf");

  //         // Get the size of the PDF (in KB, MB, etc.)
  //         const pdfSize = (pdfBlob.size / 1024).toFixed(2); // Convert to KB and round to 2 decimal places
  //         console.log("PDF size:", pdfSize, "KB");

  //         // Optionally, display the PDF size to the user
  //         const sizeText = document.createElement("p");
  //         sizeText.innerText = `PDF size: ${pdfSize} KB`;
  //         confirmationContainer.appendChild(sizeText);
  //         setShowIncomeVerfDocument(true);
  //         setIncomeFileType("image/png");
  //         setIncomeFileSize(`${pdfSize} KB`);
  //       };

  //       retakeButton.onclick = () => {
  //         // Hide confirmation and retake the image
  //         document.body.removeChild(confirmationContainer);
  //         document.body.appendChild(videoContainer); // Show video again
  //         videoElement.play();
  //       };

  //       const confirmationButtonContainer = document.createElement("div");
  //       confirmationButtonContainer.style.display = "flex";
  //       confirmationButtonContainer.style.flexDirection = "column";
  //       confirmationButtonContainer.style.width = "250px";
  //       // confirmationButtonContainer.style.f = 'space-between';
  //       confirmationButtonContainer.appendChild(confirmButton);
  //       confirmationButtonContainer.appendChild(retakeButton);

  //       confirmationContainer.appendChild(imagePreview);
  //       confirmationContainer.appendChild(confirmationButtonContainer);

  //       // Remove video container (optional, you can show it again later if needed)
  //       document.body.removeChild(videoContainer);
  //       document.body.appendChild(confirmationContainer); // Show the confirmation screen
  //     };

  //     // Append the message overlay to the video container
  //     videoContainer.appendChild(messageOverlay);
  //     if (isMobile) {
  //       videoContainer.appendChild(flipIcon);
  //       videoContainer.appendChild(closeIcon);
  //     }
  //     videoContainer.appendChild(videoElement);
  //     if (isMobile) {
  //       videoContainer.appendChild(flipIcon);
  //       videoContainer.appendChild(closeIcon);
  //     }
  //     videoContainer.appendChild(captureButton);
  //     videoContainer.appendChild(squareOverlay); // Add the square overlay
  //     document.body.appendChild(videoContainer);
  //   } catch (error: any) {
  //     alert("Unable to access the camera: " + error.message);
  //   }
  // };

  const convertImageToPdfBase64 = async (
    imageBase64: string
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      const pdfCanvas = document.createElement("canvas");
      const pdfContext = pdfCanvas.getContext("2d");

      const img = new Image();
      img.src = imageBase64;

      img.onload = () => {
        const pdfWidth = 595.28; // A4 width in points
        const pdfHeight = (img.height / img.width) * pdfWidth; // Maintain aspect ratio

        pdfCanvas.width = pdfWidth;
        pdfCanvas.height = pdfHeight;

        pdfContext?.drawImage(img, 0, 0, pdfWidth, pdfHeight);

        // Generate PDF using Blob
        const pdfBlob = generatePdfBlob(pdfCanvas);

        // Convert PDF Blob to Base64
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result as string); // Return Base64 PDF
        };
        reader.onerror = (err) => reject(err);
        reader.readAsDataURL(pdfBlob); // Ensure it's read as a PDF
      };

      img.onerror = () => reject(new Error("Failed to load image"));
    });
  };

  // Helper function to generate a PDF Blob
  const generatePdfBlob = (canvas: HTMLCanvasElement): Blob => {
    const pdfHeader: any = [
      "%PDF-1.4\n",
      "1 0 obj << /Type /Catalog /Pages 2 0 R >> endobj\n",
      "2 0 obj << /Type /Pages /Kids [3 0 R] /Count 1 >> endobj\n",
      "3 0 obj << /Type /Page /Parent 2 0 R /Resources << /XObject << /Im1 4 0 R >> >>\n",
      "/MediaBox [0 0 595.28 841.89] /Contents 5 0 R >> endobj\n",
    ];

    const pdfFooter = "%%EOF";

    // Image to PDF object stream
    const imageStream = canvas
      .toDataURL("image/png")
      .replace("data:image/png;base64,", "");
    const imageBytes = atob(imageStream);
    const imageData = new Uint8Array(imageBytes.length);

    for (let i = 0; i < imageBytes.length; i++) {
      imageData[i] = imageBytes.charCodeAt(i);
    }

    const pdfStream: any = new Blob(
      [
        ...pdfHeader,
        "/XObject << /Im1 << /Subtype /Image /Type /XObject /Width 595 /Height 841 /ColorSpace /DeviceRGB /BitsPerComponent 8 /Length ",
        imageData.length,
        " >> stream\n",
        imageData,
        "\nendstream endobj\n",
        pdfFooter,
      ],
      { type: APPLICATION_PDF }
    );

    return pdfStream;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      handleFiles(Array.from(files));
    }
  };

  const handleFiles = async (files: File[]) => {
    const validFiles = files.filter((file) =>
      ["image/png", "image/jpeg", APPLICATION_PDF].includes(file.type)
    );

    setUploadedFiles((prev) => [...prev, ...validFiles]);

    // Convert each file into a Base64 string
    const base64Promises = await validFiles.map(async (file) =>  convertToBase64(file));
    const base64Results = await Promise.all(base64Promises);

    // Save Base64 results to state
    setBase64Files((prev) => [...prev, ...base64Results]);

    handleFileDetails(files);
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleFiles(Array.from(event.dataTransfer.files));
  };

  const handleFileDetails = (files: any) => {
    const fileSizeInBytes = files[0].size; // Get file size in bytes
    setIncomeFileType(files[0].type); // Get file type
    setIncomeDoc(files[0]);
    console.log("Income document", files[0]);
    // Convert bytes to KB, MB, or GB
    let fileSize: any = fileSizeInBytes;
    let sizeUnit = "bytes";

    if (fileSizeInBytes >= 1024) {
      fileSize = fileSizeInBytes / 1024;
      sizeUnit = "KB";
    }

    if (fileSize >= 1024) {
      fileSize = fileSize / 1024;
      sizeUnit = "MB";
    }

    if (fileSize >= 1024) {
      fileSize = fileSize / 1024;
      sizeUnit = "GB";
    }

    // Round the file size to 2 decimal places
    fileSize = fileSize.toFixed(2);

    // Set the file size to the state
    setIncomeFileSize(`${fileSize} ${sizeUnit}`);
    if (SUPPORTED_FILE_TYPE.includes(files[0].type)) {
      if (sizeUnit !== "KB") {
        if ((sizeUnit == "MB" && Number(fileSize) >= 5 && files[0].type == 'application/pdf')) {
          setMessagePopup(true);
          setErrorMessage("Please upload a file smaller than 5MB.");
          setIncomeDoc("");
        } else if ((sizeUnit == "MB" && Number(fileSize) > 20) || sizeUnit == "GB") {
          setMessagePopup(true);
          setErrorMessage("Please upload a file smaller than 20MB.");
          setIncomeDoc("");
        } else {
          setShowIncomeVerfDocument(true);
        }
      } else {
        setShowIncomeVerfDocument(true);
      }
    } else {
      setMessagePopup(true);
      setErrorMessage(
        "Invalid file format. Please upload a PDF, JPEG, or PNG."
      );
      setIncomeDoc("");
    }
  };

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(new Error("Failed to convert file."));
      reader.readAsDataURL(file);
    });
  };

  const handleNext = async () => {
    setShowIncomeDetails(true)
    console.log("IncomeDOc data", incomeDoc);
    const documentData = await handleFile(incomeDoc);
    console.log("Document data", documentData);
    setIncomeDocCopy(incomeDoc)
    setIncomeFileTypeCopy(incomeFileType)
    setIncomeFileSizeCopy(incomeFileSize)
    if (source == "review") {
      history.push(`/review/details/${customerId}/${approvalId}`);
      uploadDocuments(approvalId, [
        {
          documentType: "EMPDOC",
          file: documentData?.base64String,
        },
      ]);
    } else {
      history.push(`/${getNextNavigator('Income', dynamicHeader)}/${customerId}/${approvalId}`);
      updateCustomerApproval("EMPLOYMENT", approvalId, customerId);
      uploadDocuments(approvalId, [
        {
          documentType: "EMPDOC",
          file: documentData?.base64String,
        },
      ]);
    }
  };
  const navigateToPreviousPage = () => {
    if (source == "review") {
      history.push(`/review/details/${customerId}/${approvalId}`);
    } else {
      const getBackNavigatorRes = getBackNavigator('Income', dynamicHeader)
      if (getBackNavigatorRes) {
        history.push(`/${getBackNavigatorRes}/${customerId}/${approvalId}`);
      }
      else {
        history.push(`/residence/${customerId}/${approvalId}`);
      }

    }
  };

  const pdfModelContent = () => {
    return <FileToPDFViewer file={incomeDoc} />;
  };

  const handleInputChanges = (e: any) => {
    e.target.value = null;
  };

  return (
    <Grid>
      <ScrollToTop/>
      {error ? <SomethingWentWrongPopUp /> : null}
      {messagePopup ? (
        <MessagePopup
          value={{
            message: errorMessage,
            setFunction: setMessagePopup,
            cleanupFunction: setErrorMessage,
          }}
        />
      ) : null}
      {loader ? <ScreenLoader /> : null}
      {/*DOFHeader is used to dynamically display the RACLogo and Progress Bar based on the Tab and BusinessType. 
      Simply provide the activeStep as your current tab, mark the completed tabs as true, and set the current and 
      next step tabs as false.*/}
      <Grid>
        <DOFHeader
          identity={true}
          residence={true}
          income={false}
          reference={false}
          activeStep="Income"
        />
      </Grid>
      {/*getTabsHeading is used to dynamically bind headings based on the Tab. Just provide the tab name, 
      and it will return the heading.*/}
      <Grid>{getTabsHeading("income")}</Grid>
      <Grid className={`${classes.leftAlign} ${classes.tabGridStyle}`}>
        <Grid
          className={`${classes.leftAlign} ${classes.minWidth38p} ${classes.p16px}`}
        >
          <Typography className={`${classes.boldFont1}`}>
            Proof of Income
          </Typography>
          <Typography
            className={`${classes.leftAlign} ${classes.pl16px} ${classes.pt10px}`}
          >
            Accepted proof of income documents, listed below (choose one):
          </Typography>
          <ul className={`${classes.pl35px}`}>
            {EMPLOYMENT_TAB_DOCUMENTS_LIST.map((doc: any) =>
              doc.type == BooleanRepresentation.Primary ? (
                <li className={`${classes.pb5px}`}>{doc.value}</li>
              ) : doc.type == BooleanRepresentation.Secondary &&
                showSecondaryDoc ? (
                <li className={`${classes.pb5px}`}>{doc.value}</li>
              ) : null
            )}
          </ul>
          <Typography
            className={`${classes.cursorPointer} ${classes.leftAlign} ${classes.pl16px} ${classes.primaryFontColor} ${classes.underline}`}
            onClick={() => setShowSecondaryDoc(!showSecondaryDoc)}
          >
            {showSecondaryDoc
              ? `Hide Document`
              : `View More Documents Examples`}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={`${classes.tabGridStyle} ${classes.width100p}`}>
        <Grid className={`${classes.tabGridStyle}`}>
          <Typography
            variant="h6"
            className={`${classes.font16px} ${classes.p10px}`}
          >
            Choose any method below
          </Typography>

          {/* <RACButton
            variant="outlined"
            color="primary"
            onClick={openCamera}
            startIcon={<CameraIcon />}
            className={`${classes.mb15px} ${classes.buttonBorderColor} ${classes.blackFont} ${classes.font16px} ${classes.w91p}`}
          >
            OPEN CAMERA AND TAKE PHOTO
          </RACButton> */}
          {DocumentCapture(setIncomeDoc, setIncomeFileSize, setIncomeFileType)}

          <Paper
            className={`${classes.drapNDropStyling} ${classes.w91p} ${classes.mb20px}`}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <UploadIcon className={`${classes.mt20px} ${classes.mb15px}`} />
            </Box>
            <label>
              <input
                type="file"
                accept=".png,.jpeg,.jpg,.pdf"
                multiple
                onChange={(e) => {
                  handleFileChange(e);
                  handleInputChanges(e);
                }}
                style={{ display: "none" }}
              />
              <Typography
                variant="body2"
                component="span"
                color="primary"
                className={`${classes.underline} ${classes.cursorPointer} ${classes.primaryFontColor} ${classes.font16px}`}
              >
                Choose a file or drag it here
              </Typography>
            </label>
            <Typography
              variant="body2"
              className={`${classes.mt10px} ${classes.lightGreyColor} ${classes.font14px} ${classes.p10px}`}
            >
              JPEG, PNG, PDF up to 2MB
            </Typography>
          </Paper>

          {showIncomeVerfDocument && (
            <Grid
              style={{
                width: "100%",
                textAlign: "left",
                padding: "10px",
                display: "flex",
                paddingTop: "0px",
                cursor: "pointer",
              }}
            >
              {incomeFileType &&
                (incomeFileType == "image/png" ||
                  incomeFileType == "image/jpg" ||
                  incomeFileType.includes("image")) && (
                  <ImageIcon style={{ width: "20px", height: "20px" }} />
                )}
              {incomeFileType && incomeFileType == APPLICATION_PDF && (
                <PdfIcon style={{ width: "20px", height: "20px" }} />
              )}
              <Typography
                className={`${classes.primaryFontColor} ${classes.pl10px}`}
                onClick={() => {
                  setOpenPdf(true);
                }}
              >
                Proof of income{" "}
                <span className={`${classes.fileSizeColor}`}>{incomeFileSize}</span>
              </Typography>
              <DeleteIcon
                style={{
                  width: "15px",
                  height: "15px",
                  marginLeft: "10px",
                  marginTop: "3px",
                }}
                onClick={() => {
                  setShowIncomeVerfDocument(false);
                  setIncomeDoc("");
                }}
              />
            </Grid>
          )}

          <RACButton
            variant="contained"
            color="primary"
            disabled={!showIncomeVerfDocument}
            onClick={() => handleNext()}
            className={`${classes.mb15px} ${classes.font16px} ${classes.primaryButtonColor} ${classes.w91p}`}
          >
            SAVE & CONTINUE
          </RACButton>

          {(dynamicHeader && dynamicHeader.length && dynamicHeader?.filter((obj: any) => obj?.label != 'Identity').findIndex((obj: any) => obj.label == 'Income') != 0) || source == "review" ?

            <RACButton
              variant="outlined"
              color="primary"
              onClick={() => {
                navigateToPreviousPage();
              }}
              className={`${classes.mb15px} ${classes.font16px} ${classes.buttonBorderColor} ${classes.primaryFontColor} ${classes.w91p}`}
            >
              BACK
            </RACButton>
            : null}

          {getNoteText()}
        </Grid>
      </Grid>
      <RACModalCard
        borderRadius="15px"
        closeIcon={true}
        isOpen={openPdf}
        maxWidth={"sm"}
        className={`${classes.customModal}`}
        title={`Proof of Residence`}
        onClose={() => setOpenPdf(false)}
      >
        {pdfModelContent()}
      </RACModalCard>
    </Grid>
  );
};
