/* eslint-disable  */
/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  Grid,
  List,
  RACButton,
  RACCheckBox,
  RACModalCard,
  Typography,
} from "@rentacenter/racstrap";
import React, { useState, useContext, useEffect } from "react";
import { VerificationStyles } from "../../../styles/verificationStyles";
import { useHistory, useParams } from "react-router-dom";
import doflogo from "../../../images/logo.svg";
import ScreenLoader from "../Common/ScreenLoader";
import { DigitalOrderFormContext } from "../Context/DigitialOrderFormContext";
import { ReactComponent as PDFIcon } from "./../../../images/pdf-icon.svg";
import FileToPDFViewer from "../Common/FileToPdfViewer";
import { ReferenceTabContext } from "../Context/ReferenceDetailsContext";
import { formatMobileNumber, PublishDOFNotificationFn } from "../Common/HelperFunctions";
import { ResidenceTabContext } from "../Context/ResidenceDetailsContext";
import { CustomerInfo } from "../../../interfaces/CustomerVerificationModel/DigitalOrderFormModel";
import { getCustomerDetails } from "../Common/GetCustomerDetails";
import { GetApproval } from "../../../api/user";
import ScrollToTop from "../Common/ScrollToTop";

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ReviewInformation = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const source = queryParams.get("source");
  const classes: any = VerificationStyles();
  const dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues");
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  const clientId: any = sessionStorageValues?.clientId
  const {
    identityFrontDoc,
    incomeDocCopy,
    showResidenceDetails,
    showIdentityDetails,
    showIncomeDetails,
    showReferenceDetails,
    dynamicHeader,
    customerDetails,
    setCVOCustomerDetails,
    decisionEngineDetails,
    residenceDoc, setResidenceDoc,
    setIncomeDoc,
    ecomSubmission,
    incomeFileTypeCopy,
    incomeFileSizeCopy,
    setIncomeFileType,
    setIncomeFileSize
  } = useContext(DigitalOrderFormContext);
  const {
    landlordResidence,
    landlordResidenceCopy, setLandlordResidence,
    residenceTenureTypeOptions,
    resFileSizeCopy, resFileTypeCopy,
    setFileSize,
    setFileType
  } = useContext(ResidenceTabContext);
  const {
    relationshipOptions,
    setFamilyReferenceDetails,
    setnonFamilyReferenceDetails,
    familyReferenceDetails,
    nonFamilyReferenceDetails,
    familyReferenceDetailsCopy,
    nonFamilyReferenceDetailsCopy

  } = useContext(ReferenceTabContext);
  const history = useHistory();
  const { approvalId, customerId } = useParams<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [customer, setCustomer] = useState<string>("");
  const [pdfData, setPdfData] = useState<string>("");
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const [pdfTitle, setPdfTitle] = useState<string>("");
  const [pdfUrl, setPDFUrl] = useState<string>("");
  const [policyAgreed, setPolicyAgreed] = useState<boolean>(false);



  // eslint-disable-next-line no-console
  console.log(
    "Residence",
    landlordResidenceCopy
  );

  useEffect(() => {
    // Call sendHeightToParent initially to send the height on mount
    sendHeightToParent();
    // Add an event listener for window resizing
    const handleResize = () => {
      sendHeightToParent();
    };
    window.addEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!customerDetails?.personalInfo?.customerId) {
      fetchCustomerData(customerId)
    }
  }, [loader]);

  // Adding the Google Analytics tags

  useEffect(() => {
    window.dataLayer?.push({
      'event': 'Loaded the DOF Review Information Page',
      'storeNo': sessionStorageValues?.storeNumber,
      'approvalId': sessionStorageValues?.approvalId,
      'currentScreen': 'DOF Review Information Screen',
      'previousScreen': 'DOF Reference Verification Screen',
    });
  }, [])

  const sendHeightToParent = () => {
    if (!loader) {
      const iframeHeight = document.body.scrollHeight; // Get the current height of the iframe content
      const iframeWidth = document.body.scrollWidth;
      window.parent.postMessage(
        {
          message: "racpad-styling",
          minHeight: iframeHeight < 540 ? 540 : iframeHeight,
          currentHeight: iframeHeight,
          iframeWidth: iframeWidth,
          verificationProcessCompleted: false
        },
        "*"
      );
    }
  };

  const renderBorderBottomLine = () => {
    return (
      <Grid
        style={{
          borderBottom: "1px solid #D9D9D9",
          width: "90%",
          paddingLeft: "20px",
          marginTop: "20px",
        }}
      ></Grid>
    );
  };

  const pdfModelContent = () => {
    return <FileToPDFViewer file={pdfData} />;
  };

  const handlePdfDocumentOpen = (docType: string) => {
    if (docType == "INC") {
      setPdfData(incomeDocCopy);
      setPdfTitle("Proof of Income");
      setOpenPdf(true);
    } else if (docType == "RESI") {
      setPdfData(landlordResidenceCopy?.residenceDocCopy);
      setPdfTitle("Proof of Residence");
      setOpenPdf(true);
    }
  };

  const handleSubmitDetails = async () => {
    setLoader(true)


    const request: any = {
      decisionId: approvalId
    }
    const getApprovalServiceResponse: any = await GetApproval(request, sessionStorageValues?.authKey ? false : sessionStorageValues?.storeNumber,
      sessionStorageValues?.authKey ? sessionStorageValues?.authKey : sessionStorageValues?.storeNumber);
    await PublishDOFNotificationFn(getApprovalServiceResponse, customerDetails, sessionStorageValues?.salesLeadId, sessionStorageValues)
    setLoader(false)
    window.parent.postMessage(
      {
        status: 200,
        message: "DOF Verification Completed",
        verificationProcessCompleted: true
      },
      "*"
    );
    if ((atob(clientId) == "EC" || atob(clientId) == "ECOM")) {
      if (ecomSubmission) {
        history.push(`/dof/submission/${customerId}/${approvalId}`);
      }
    }
    else {
      history.push(`/dof/submission/${customerId}/${approvalId}`);
    }

  };
  // Main function to fetch customer details
  const fetchCustomerData = async (customerId: string, getCustomerCal?: any) => {
    try {
      if (!customerDetails?.personalInfo?.customerId || getCustomerCal) {
        setLoader(true)
        const customerData: CustomerInfo = await getCustomerDetails(
          customerId,
          setCVOCustomerDetails,
          landlordResidence,
          setLandlordResidence,
          familyReferenceDetails,
          setFamilyReferenceDetails,
          nonFamilyReferenceDetails,
          setnonFamilyReferenceDetails
        );
        setLoader(false)
        console.log("customerDataReponse in Review screen", customerData);

        setCVOCustomerDetails(customerData);
      }

    } catch (error: any) {
      console.log("Error in fetchCustomerData service", error);
    }
  };

  const handleEditDetailsRedirect = (tabToSwitch: string) => {
    history.push(`/${tabToSwitch}/${customerId}/${approvalId}?source=review`);
  };

  const handleBackButton = () => {
    const backPage: string = dynamicHeader?.[dynamicHeader.length - 1]?.label;
    history.push(`/${(backPage?.toLowerCase())}/${customerId}/${approvalId}`);
  }

  return (
    <>
      <ScrollToTop />
      {loader ? <ScreenLoader /> : null}

      <Grid style={{ width: "100%", height: "80vh" }}>
        <Grid style={{ marginTop: "7%" }}>
          {(atob(clientId) == "EC" || atob(clientId) == "ECOM") ? null : (
            <Grid style={{ textAlign: "center", display: "inline-flex" }}>
              <img
                src={doflogo}
                style={{
                  float: "left",
                  verticalAlign: "middle",
                  padding: "16px 24px",
                }}
              />
            </Grid>
          )}

          <Typography
            className={`${classes.primaryFontColor} ${classes.responsiveFontTitle} ${classes.boldFont1} ${classes.p10px}`}
          >
            Review your Information
          </Typography>

          <Grid>
            <Typography
              className={`${classes.responsiveFontStyle} ${classes.p10px} ${classes.responsiveText}`}
            >
              Please review your information to ensure it is accurate before
              submitting for verification.
            </Typography>
          </Grid>
          {/* <Grid className={`${classes.displayFlex} ${classes.flexDirectionColumn} ${classes.width100p} ${classes.maxWidth45p} ${classes.justifyContentCenter}`}> */}
          <Grid className={`${classes.responsiveWidth}`}>
            {showIdentityDetails && (
              <Grid
                className={`${classes.justifyContentCenter} ${classes.displayFlex}`}
              >
                <Grid
                  className={`${classes.p10px} ${classes.pl30px} ${classes.width100p} ${classes.displayFlex} ${classes.textAlignLeft} ${classes.flexDirectionColumn}`}
                >
                  <Typography className={`${classes.responsiveFontStyle}`}>
                    Identity Details
                  </Typography>
                  <Typography
                    className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont}`}
                  >
                    ID verification has been submitted
                  </Typography>
                  {renderBorderBottomLine()}
                </Grid>
              </Grid>
            )}

            {showResidenceDetails && (
              <Grid
                className={`${classes.justifyContentCenter} ${classes.displayFlex}`}
              >
                <Grid
                  className={`${classes.p10px} ${classes.pl30px} ${classes.width100p} ${classes.displayFlex} ${classes.textAlignLeft} ${classes.flexDirectionColumn}`}
                >
                  <Grid
                    className={`${classes.displayFlex} ${classes.spaceBetween} ${classes.marginRight10p}`}
                  >
                    <Typography className={`${classes.responsiveFontStyle}`}>
                      Residence Details
                    </Typography>
                    <Typography
                      className={`${classes.responsiveFontStyle}  ${classes.primaryFontColor} ${classes.underline} ${classes.cursorPointer}`}
                      onClick={() => {
                        handleEditDetailsRedirect("residence");
                        setLandlordResidence({
                          ...landlordResidence, landLordName: landlordResidenceCopy?.landLordName, timeAtCurrentAddress: landlordResidenceCopy?.timeAtCurrentAddress, formatedMobileNumber: landlordResidenceCopy?.landLordPhoneNumber ? formatMobileNumber(landlordResidenceCopy?.landLordPhoneNumber) : ''
                        });
                        {
                          landlordResidenceCopy?.residenceDocCopy ? setResidenceDoc(landlordResidenceCopy?.residenceDocCopy) : setResidenceDoc('')
                        };
                        setFileSize(resFileSizeCopy)
                        setFileType(resFileTypeCopy)
                      }}
                    >
                      Edit Details
                    </Typography>
                  </Grid>
                  {landlordResidenceCopy?.residenceDocCopy && (<Grid className={`${classes.displayFlex} ${classes.mt10px}`}>
                    <Typography
                      className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont}`}
                    >
                      Document:
                    </Typography>
                    <PDFIcon
                      className={`${classes.pdfIconStyle} ${classes.cursorPointer}`}
                      onClick={() => {
                        handlePdfDocumentOpen("RESI");
                      }}
                    />
                    <Typography
                      className={`${classes.responsiveFontStyle} ${classes.primaryFontColor}  ${classes.pl5px} ${classes.cursorPointer}`}
                      onClick={() => {
                        handlePdfDocumentOpen("RESI");

                      }}
                    >
                      Proof of Residence
                    </Typography>
                  </Grid>)}
                  {(landlordResidenceCopy?.landLordName && !landlordResidenceCopy?.residenceDocCopy) && (
                    <Grid
                      className={`${classes.displayFlex} ${classes.mt10px} ${classes.flexDirectionColumn}`}
                    >

                      <Grid
                        className={`${classes.displayFlex} ${classes.mt10px} ${classes.flexDirectionColumn}`}
                      >
                        <Grid className={`${classes.displayFlex}`}>
                          <Typography
                            className={`${classes.responsiveFontStyle} ${classes.semiboldFont}`}
                          >
                            {`Landlord’s / Apartment Name : `}
                          </Typography>
                          <Typography
                            className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont} ${classes.ml10px} ${classes.ml5px}`}
                          >
                            {landlordResidenceCopy?.landLordName
                              ? landlordResidenceCopy?.landLordName
                              : ''}
                          </Typography>
                        </Grid>
                        <Grid className={`${classes.displayFlex}`}>
                          <Typography
                            className={`${classes.responsiveFontStyle} ${classes.semiboldFont}`}
                          >
                            {`Time at Current Address : `}
                          </Typography>
                          <Typography
                            className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont} ${classes.ml10px} ${classes.ml5px}`}
                          >
                            {landlordResidenceCopy?.timeAtCurrentAddress
                              ? residenceTenureTypeOptions.length ? residenceTenureTypeOptions?.find((element: any) => element.value == landlordResidenceCopy?.timeAtCurrentAddress)?.label : `` : ``}

                          </Typography>
                        </Grid>
                        <Grid className={`${classes.displayFlex}`}>
                          <Typography
                            className={`${classes.responsiveFontStyle} ${classes.semiboldFont}`}
                          >
                            {`Phone : `}
                          </Typography>
                          <Typography
                            className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont} ${classes.ml10px} ${classes.ml5px}`}
                          >
                            {landlordResidenceCopy?.landLordPhoneNumber
                              ? formatMobileNumber(
                                landlordResidenceCopy?.landLordPhoneNumber
                              )
                              : ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {renderBorderBottomLine()}
                </Grid>
              </Grid>
            )}

            {showIncomeDetails && incomeDocCopy && (
              <Grid
                className={`${classes.justifyContentCenter} ${classes.displayFlex}`}
              >
                <Grid
                  className={`${classes.p10px} ${classes.pl30px} ${classes.width100p} ${classes.displayFlex} ${classes.textAlignLeft} ${classes.flexDirectionColumn}`}
                >
                  <Grid
                    className={`${classes.displayFlex} ${classes.spaceBetween} ${classes.marginRight10p}`}
                  >
                    <Typography className={`${classes.responsiveFontStyle}`}>
                      Income Details
                    </Typography>
                    <Typography
                      className={`${classes.responsiveFontStyle}  ${classes.primaryFontColor} ${classes.underline} ${classes.cursorPointer}`}
                      onClick={() => {
                        handleEditDetailsRedirect("income");
                        setIncomeDoc(incomeDocCopy)
                        setIncomeFileType(incomeFileTypeCopy)
                        setIncomeFileSize(incomeFileSizeCopy)
                      }}
                    >
                      Edit Details
                    </Typography>
                  </Grid>
                  <Grid className={`${classes.displayFlex} ${classes.mt10px}`}>
                    <Typography
                      className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont}`}
                    >
                      Document:
                    </Typography>
                    <PDFIcon
                      className={`${classes.pdfIconStyle} ${classes.cursorPointer}`}
                      onClick={() => {
                        handlePdfDocumentOpen("INC");
                      }}
                    />
                    <Typography
                      className={`${classes.responsiveFontStyle} ${classes.primaryFontColor}  ${classes.pl5px} ${classes.cursorPointer}`}
                      onClick={() => {
                        handlePdfDocumentOpen("INC");
                      }}
                    >
                      Proof of income
                    </Typography>
                  </Grid>
                  {renderBorderBottomLine()}
                </Grid>
              </Grid>
            )}

            {showReferenceDetails && (
              <Grid
                className={`${classes.justifyContentCenter} ${classes.displayFlex}`}
              >
                <Grid
                  className={`${classes.p10px} ${classes.pl30px} ${classes.width100p} ${classes.displayFlex} ${classes.textAlignLeft} ${classes.flexDirectionColumn}`}
                >
                  <Grid
                    className={`${classes.displayFlex} ${classes.spaceBetween} ${classes.marginRight10p}`}
                  >
                    <Typography className={`${classes.responsiveFontStyle}`}>
                      Reference Details
                    </Typography>
                    <Typography
                      className={`${classes.responsiveFontStyle}  ${classes.primaryFontColor} ${classes.underline} ${classes.cursorPointer}`}
                      onClick={async () => {
                        await fetchCustomerData(customerId, true)
                        handleEditDetailsRedirect("reference");
                        // familyReferenceDetailsCopy?.referenceName ? setFamilyReferenceDetails({ ...familyReferenceDetails, referenceName: familyReferenceDetailsCopy?.referenceName, relationShipType: familyReferenceDetailsCopy.relationShipType, formatedMobileNumber: formatMobileNumber(familyReferenceDetailsCopy?.phoneNumber) }) : null;
                        // nonFamilyReferenceDetailsCopy?.referenceName ? setnonFamilyReferenceDetails({ ...nonFamilyReferenceDetails, referenceName: nonFamilyReferenceDetailsCopy?.referenceName, relationShipType: nonFamilyReferenceDetailsCopy.relationShipType, formatedMobileNumber: formatMobileNumber(nonFamilyReferenceDetailsCopy?.phoneNumber) }) : null;
                      }}
                    >
                      Edit Details
                    </Typography>
                  </Grid>
                  {(familyReferenceDetailsCopy?.referenceName ||
                    familyReferenceDetailsCopy?.actualReferenceName) && (
                      <Grid
                        className={`${classes.displayFlex} ${classes.mt10px} ${classes.flexDirectionColumn}`}
                      >
                        <Typography
                          className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont}`}
                        >
                          Reference 1 Details
                        </Typography>
                        <Grid
                          className={`${classes.displayFlex} ${classes.mt10px} ${classes.flexDirectionColumn}`}
                        >
                          <Grid className={`${classes.displayFlex}`}>
                            <Typography
                              className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont}`}
                            >
                              Name :
                            </Typography>
                            <Typography
                              className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont} ${classes.ml5px}`}
                            >
                              {familyReferenceDetailsCopy?.referenceName
                                ? ' ' + familyReferenceDetailsCopy?.referenceName
                                : ' ' + familyReferenceDetailsCopy?.actualReferenceName}
                            </Typography>
                          </Grid>
                          <Grid className={`${classes.displayFlex}`}>
                            <Typography
                              className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont}`}
                            >
                              Relationship:
                            </Typography>
                            <Typography
                              className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont} ${classes.ml5px}`}
                            >
                              {familyReferenceDetailsCopy?.relationShipType
                                ? relationshipOptions.length ? relationshipOptions?.find((element: any) => element.value == familyReferenceDetailsCopy?.relationShipType)?.label : familyReferenceDetailsCopy?.relationShipType
                                : relationshipOptions.length ? relationshipOptions?.find((element: any) => element.value == familyReferenceDetailsCopy?.actualRelationShipType)?.label : familyReferenceDetailsCopy?.actualRelationShipType}
                            </Typography>
                          </Grid>
                          <Grid className={`${classes.displayFlex}`}>
                            <Typography
                              className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont}`}
                            >
                              Phone :
                            </Typography>
                            <Typography
                              className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont} ${classes.ml5px}`}
                            >
                              {familyReferenceDetailsCopy?.phoneNumber
                                ? formatMobileNumber(
                                  familyReferenceDetailsCopy?.phoneNumber
                                )
                                : formatMobileNumber(
                                  familyReferenceDetailsCopy?.actualPhoneNumber
                                )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  {(nonFamilyReferenceDetailsCopy?.referenceName ||
                    nonFamilyReferenceDetailsCopy?.actualReferenceName) && (
                      <Grid
                        className={`${classes.displayFlex} ${classes.mt10px} ${classes.flexDirectionColumn}`}
                      >
                        <Typography
                          className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont}`}
                        >
                          Reference 2 Details
                        </Typography>
                        <Grid
                          className={`${classes.displayFlex} ${classes.mt10px} ${classes.flexDirectionColumn}`}
                        >
                          <Grid className={`${classes.displayFlex}`}>
                            <Typography
                              className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont}`}
                            >
                              Name :
                            </Typography>
                            <Typography
                              className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont} ${classes.ml5px}`}
                            >
                              {nonFamilyReferenceDetailsCopy?.referenceName
                                ? ' ' + nonFamilyReferenceDetailsCopy?.referenceName
                                : ' ' + nonFamilyReferenceDetailsCopy?.actualReferenceName}
                            </Typography>
                          </Grid>
                          <Grid className={`${classes.displayFlex}`}>
                            <Typography
                              className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont}`}
                            >
                              Relationship:
                            </Typography>
                            <Typography
                              className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont} ${classes.ml5px}`}
                            >
                              {nonFamilyReferenceDetailsCopy?.relationShipType
                                ? relationshipOptions.length ? relationshipOptions?.find((element: any) => element.value == nonFamilyReferenceDetailsCopy?.relationShipType)?.label : nonFamilyReferenceDetailsCopy?.relationShipType
                                : relationshipOptions.length ? relationshipOptions?.find((element: any) => element.value == nonFamilyReferenceDetailsCopy?.actualRelationShipType)?.label : nonFamilyReferenceDetailsCopy?.actualRelationShipType}
                            </Typography>
                          </Grid>
                          <Grid className={`${classes.displayFlex}`}>
                            <Typography
                              className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont}`}
                            >
                              Phone :
                            </Typography>
                            <Typography
                              className={`${classes.responsiveFontStyle} ${classes.openSansRegularFont} ${classes.ml5px}`}
                            >
                              {nonFamilyReferenceDetailsCopy?.phoneNumber
                                ? formatMobileNumber(
                                  nonFamilyReferenceDetailsCopy?.phoneNumber
                                )
                                : formatMobileNumber(
                                  nonFamilyReferenceDetailsCopy?.actualPhoneNumber
                                )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  {renderBorderBottomLine()}
                </Grid>
              </Grid>
            )}

            <Grid className={`${classes.displayFlex}`}>
              <RACCheckBox
                checked={policyAgreed}
                onClick={() => {
                  setPolicyAgreed(!policyAgreed);
                }}
              />
              <Typography
                className={`${classes.adjustableMH}`}
              >
                By clicking on “Submit Verification Details” I certify that the
                information I have provided is accurate.
              </Typography>
            </Grid>

            <Grid
              className={`${classes.p10px} ${classes.mt10px} ${classes.displayFlex} ${classes.flexDirectionColumn} ${classes.alignItemsCenter}`}
            >
              <RACButton
                color="primary"
                variant="contained"
                className={`${classes.primaryButtonColor} ${classes.widthSize}`}
                disabled={!policyAgreed}
                onClick={() => {
                  handleSubmitDetails();
                }}
              >
                SUBMIT VERIFICATION DETAILS
              </RACButton>

              {(dynamicHeader && dynamicHeader.length && dynamicHeader?.filter((obj: any) => obj?.label != 'Identity').length > 0) ?
                < RACButton
                  variant="outlined"
                  color="primary"
                  className={`${classes.buttonBorderColor} ${classes.primaryFontColor} ${classes.mt10px} ${classes.widthSize}`}
                  onClick={() => {
                    handleBackButton()
                  }}
                >
                  BACK
                </RACButton>
                : null}
            </Grid>
            {/* </Grid> */}
          </Grid>
        </Grid>
        <RACModalCard
          borderRadius="15px"
          closeIcon={true}
          isOpen={openPdf}
          maxWidth={"sm"}
          className={`${classes.customModal}`}
          title={pdfTitle}
          onClose={() => setOpenPdf(false)}
        >
          {pdfModelContent()}
        </RACModalCard>
      </Grid >
    </>
  );
};
