/* eslint-disable */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Grid,
  Paper,
  RACButton,
  RACModalCard,
  RACRadio,
  RACSelect,
  RACTextbox,
  Typography,
} from "@rentacenter/racstrap";
import React, { useContext, useEffect, useState } from "react";
import { DigitalOrderFormContext } from "../Context/DigitialOrderFormContext";
import { ReactComponent as CameraIcon } from "./../../../images/cameraIcon.svg";
import { ReactComponent as UploadIcon } from "./../../../images/uploadImage.svg";
import { ReactComponent as ImageIcon } from "./../../../images/image-icon.svg";
import { ReactComponent as PdfIcon } from "./../../../images/pdf-icon.svg";
import { ReactComponent as DeleteIcon } from "./../../../images/delete-icon.svg";
import { ReactComponent as UpArrowIcon } from "./../../../images/upArrowIcon.svg";
import { ReactComponent as DownArrowIcon } from "./../../../images/downArrowIcon.svg";
import flipCameraIcon from './../../../images/camera-flip.svg';
import closeIconImage from './../../../images/close-icon.svg';

import { VerificationStyles } from "../../../styles/verificationStyles";
import DOFHeader from "./DOFHeader";
import {
  buildDropdownList,
  convertPDFToBase64,
  formatText,
  getNoteText,
  getTabsHeading,
  getNextNavigator,
  handleFile,
  updateCustomerApproval,
  uploadDocuments,
} from "../Common/HelperFunctions";
import {
  APPLICATION_PDF,
  RESIDENCE_TAB_DOCUMENT_LIST,
  SUPPORTED_FILE_TYPE,
} from "../../../constants/constants";
import { BooleanRepresentation } from "../../../constants/enums";
import { useHistory, useParams } from "react-router-dom";
import { ResidenceTabContext } from "../Context/ResidenceDetailsContext";
import { GetDropOptions, UpdateCustomerInfo } from "../../../api/user";
import jsPDF from "jspdf";
import FileToPDFViewer from "../Common/FileToPdfViewer";
import MessagePopup from "../Common/MessagePopup";
import { Validator } from "../../../utils/utils";
import { getCustomerDetails } from "../Common/GetCustomerDetails";
import { CustomerInfo } from "../../../interfaces/CustomerVerificationModel/DigitalOrderFormModel";
import { ReferenceTabContext } from "../Context/ReferenceDetailsContext";
import ScreenLoader from "../Common/ScreenLoader";
import DocumentCapture from "../Common/OpenCamera";
import ScrollToTop from "../Common/ScrollToTop";

export const ResidenceTab = () => {
  const {
    businessFlow,
    setResidenceDoc,
    residenceDoc,
    showResidenceVerfDocument,
    setShowResidenceVerfDocument,
    dynamicHeader,
    setShowResidenceDetails,
    customerDetails, setCVOCustomerDetails
  } = useContext(DigitalOrderFormContext);

  const {
    relationshipOptions,
    setRelationshipOptions,
    setFamilyReferenceDetails,
    setnonFamilyReferenceDetails,
    nonFamilyReferenceDetails,
    familyReferenceDetails


  } = useContext(ReferenceTabContext);


  const {
    landlordResidence,
    setLandlordResidence,
    manualEntryEnabled,
    setManualEntryEnabled,
    residenceType,
    setResidenceType,
    fileType,
    setFileType,
    fileSize,
    setFileSize,
    residenceTenureTypeOptions,
    setResidenceTenureTypeOptions,
    landlordResidenceCopy,
    setLandlordResidenceCopy,
    setResFileSizeCopy,
    resFileTypeCopy, setResFileTypeCopy
  } = useContext(ResidenceTabContext);
  const classes: any = VerificationStyles();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [base64Files, setBase64Files] = useState<string[]>([]);
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const [showSecondaryDoc, setShowSecondaryDoc] = useState<boolean>(false);
  const history = useHistory();
  const { customerId, approvalId } = useParams<any>();
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const queryParams = new URLSearchParams(window.location.search);
  const source = queryParams.get("source");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [messagePopup, setMessagePopup] = useState<boolean>(false);

  const [loader, setLoader] = useState<boolean>(false);

  const dofAuthSessionValue: any =
    window.sessionStorage.getItem("dofAuthValues");
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  console.log("Session Storage details", sessionStorageValues);
  const isStoreCodeFlow: any = sessionStorageValues?.authKey
    ? false
    : sessionStorageValues?.storeNumber;
  const accessToken: any = sessionStorageValues?.authKey
    ? sessionStorageValues?.authKey
    : sessionStorageValues?.storeNumber;

  const saveButtonDisable = !(residenceDoc || (landlordResidence.formatedMobileNumber && landlordResidence.formatedMobileNumber.length >= 10 && landlordResidence.formatedMobileNumber?.replace(/[^a-zA-Z0-9\s]/g, '')?.length >= 11 && landlordResidence.timeAtCurrentAddress && landlordResidence.landLordName))


  useEffect(() => {
    fetchCustomerData(customerId);
    // Call sendHeightToParent initially to send the height on mount
    sendHeightToParent();
    // Add an event listener for window resizing
    const handleResize = () => {
      sendHeightToParent();
    };
    window.addEventListener("resize", handleResize);
  }, [showSecondaryDoc, manualEntryEnabled]);

  // Adding the Google Analytics tags
  useEffect(() => {

    window.dataLayer?.push({
      'event': 'Loaded the DOF Residence Verification Page',
      'storeNo': sessionStorageValues?.storeNumber,
      'approvalId': sessionStorageValues?.approvalId,
      'currentScreen': 'DOF Residence Verification Screen',
      'previousScreen': 'DOF Identity Verification Screen',
    });
  }, [])

  useEffect(() => {
    if (landlordResidence?.landLordName || landlordResidence?.timeAtCurrentAddress || landlordResidence?.phoneNumber) {
      setManualEntryEnabled(true)
    }
  }, [landlordResidence?.landLordName || landlordResidence?.timeAtCurrentAddress || landlordResidence?.phoneNumber])


  useEffect(() => {
    if (!residenceDoc) {
      setShowResidenceVerfDocument(false)
    }
    else if (residenceDoc) {
      setShowResidenceVerfDocument(true)
    }
  }, [residenceDoc])

  // Main function to fetch customer details
  const fetchCustomerData = async (customerId: string) => {
    try {
      if (!customerDetails?.personalInfo?.customerId) {
        setLoader(true)

        const customerData: CustomerInfo = await getCustomerDetails(
          customerId,
          setCVOCustomerDetails,
          landlordResidence,
          setLandlordResidence,
          familyReferenceDetails,
          setFamilyReferenceDetails,
          nonFamilyReferenceDetails,
          setnonFamilyReferenceDetails

        );
        console.log("customerDataReponse", customerData);


        setCVOCustomerDetails(customerData);
        setLoader(false)

      }
      if (relationshipOptions?.length < 1) {
        const dropOptionsPayload = {
          references: [
            'RELATIONSHIP_TYPE',
            'RESIDENCE_TENURE_TYPE'
          ],
          status: 'Y',
        };
        setLoader(true)
        const getDropOptionsRes: any = await GetDropOptions(dropOptionsPayload, isStoreCodeFlow, accessToken)
        setLoader(false);

        console.log("getDropOptionsResponse", getDropOptionsRes)
        if (getDropOptionsRes) {
          setRelationshipOptions(
            buildDropdownList(getDropOptionsRes?.relationshipType)
          );
          setResidenceTenureTypeOptions(buildDropdownList(getDropOptionsRes?.residenceTenureType)
          )
        }
      }
    } catch (error: any) {
      console.log("Error in fetchCustomerData service", error);
      setLoader(false);
    }
  };


  const sendHeightToParent = () => {
    const iframeHeight = document.body.scrollHeight; // Get the current height of the iframe content
    const iframeWidth = document.body.scrollWidth;
    window.parent.postMessage(
      {
        message: "racpad-styling",
        minHeight: iframeHeight < 640 ? 640 : iframeHeight,
        currentHeight: iframeHeight,
        iframeWidth: iframeWidth,
        verificationProcessCompleted: false
      },
      "*"
    );
  };



  // const openCamera = async () => {
  //   try {
  //     let currentDeviceId: any = null;
  //     let currentStream: any = null;
  //     // Detect if the user is on a mobile device by checking the screen size and touch events
  //     const isMobile =
  //       window.matchMedia("(max-width: 768px)").matches ||
  //       /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

  //     // List all media devices (microphones, cameras, etc.)
  //     const devices = await navigator.mediaDevices.enumerateDevices();

  //     // Find all video devices (cameras)
  //     const videoDevices = devices.filter(
  //       (device) => device.kind === "videoinput"
  //     );

  //     // Identify front and back cameras
  //     const frontCamera: any = videoDevices.find((device) =>
  //       device.label.toLowerCase().includes("front")
  //     );
  //     const backCameras: any = videoDevices.find((device) =>
  //       device.label.toLowerCase().includes("back")
  //     );

  //     // Set the current device to the first available camera
  //     const defaultDevice = videoDevices[0];
  //     currentDeviceId = isMobile ? backCameras?.deviceId ? backCameras?.deviceId : defaultDevice.deviceId : defaultDevice.deviceId;


  //     // Start the video stream using the selected camera device
  //     const stream = await navigator.mediaDevices.getUserMedia({
  //       video: { deviceId: currentDeviceId },
  //     });

  //     const canvas = document.createElement("canvas");
  //     const context = canvas.getContext("2d");

  //     // Create a text message element
  //     const messageOverlay = document.createElement("div");
  //     messageOverlay.innerText = "Capture the document within the red area";
  //     messageOverlay.style.position = "absolute";
  //     messageOverlay.style.top = "7.8%"; // Adjust this to position the message
  //     messageOverlay.style.left = "50%";
  //     messageOverlay.style.transform = "translateX(-50%)"; // Center horizontally
  //     messageOverlay.style.fontSize = "14px"; // Font size for visibility
  //     messageOverlay.style.fontWeight = "bold";
  //     messageOverlay.style.color = "#fff"; // White text color
  //     messageOverlay.style.textAlign = "center"; // Center the text
  //     messageOverlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)"; // Semi-transparent background for better readability
  //     messageOverlay.style.padding = "10px"; // Some padding for text clarity
  //     messageOverlay.style.borderRadius = "5px";

  //     const videoContainer = document.createElement("div");
  //     videoContainer.style.position = "fixed";
  //     videoContainer.style.top = "0";
  //     videoContainer.style.left = "0";
  //     videoContainer.style.width = "100%";
  //     videoContainer.style.height = "100%";
  //     videoContainer.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
  //     videoContainer.style.display = "flex";
  //     videoContainer.style.flexDirection = "column";
  //     videoContainer.style.justifyContent = "center";
  //     videoContainer.style.alignItems = "center";
  //     videoContainer.style.zIndex = "1001";

  //     // Square overlay style
  //     const squareOverlay = document.createElement("div");
  //     squareOverlay.style.position = "absolute";
  //     squareOverlay.style.border = "2px solid #ff0000"; // Red border for visibility
  //     squareOverlay.style.width = "300px"; // Size of the crop area (adjust as needed)
  //     squareOverlay.style.height = "350px"; // Same as above
  //     squareOverlay.style.top = "47%";
  //     squareOverlay.style.left = "50%";
  //     squareOverlay.style.transform = "translate(-50%, -50%)"; // Center the square
  //     squareOverlay.style.zIndex = "1002";

  //     // Create the camera flip icon only for mobile devices
  //     const flipIcon = document.createElement("img");
  //     flipIcon.src = flipCameraIcon; // Replace with your flip camera icon URL
  //     flipIcon.style.position = "absolute";
  //     flipIcon.style.top = "12%";
  //     flipIcon.style.left = "90%";
  //     flipIcon.style.transform = "translateX(-50%)"; // Center horizontally
  //     flipIcon.style.cursor = "pointer";
  //     flipIcon.style.width = "50px"; // Adjust size as needed
  //     flipIcon.style.height = "50px"; // Adjust size as needed
  //     flipIcon.style.background = "rgba(0, 0, 0, 0.5)";
  //     flipIcon.style.borderRadius = "50%";
  //     flipIcon.style.padding = "5px";
  //     flipIcon.style.border = "1px solid #ffffff";

  //     flipIcon.onclick = async () => {
  //       // Stop the current stream tracks before switching the camera
  //       currentStream.getTracks().forEach((track: any) => track?.stop());

  //       // // Switch to the next camera (front/back)
  //       // const currentIndex = videoDevices.findIndex(
  //       //   (device) => device.deviceId === currentDeviceId
  //       // );
  //       // const nextDeviceIndex = (currentIndex + 1) % videoDevices.length;

  //       const nextDeviceId = frontCamera.deviceId == currentDeviceId ? backCameras.deviceId : frontCamera.deviceId;

  //       if (nextDeviceId) {
  //         // Start the new stream with the selected deviceId
  //         const newStream = await navigator.mediaDevices.getUserMedia({
  //           video: { deviceId: nextDeviceId },
  //         });

  //         // Reset the video stream and update the source
  //         videoElement.srcObject = newStream;
  //         videoElement.play();
  //         currentStream = newStream;
  //         currentDeviceId = nextDeviceId;
  //       }
  //     };

  //     // Create the close icon to close the document capture
  //     const closeIcon = document.createElement("img");
  //     closeIcon.src = closeIconImage; // Replace with your close icon URL
  //     closeIcon.style.position = "absolute";
  //     closeIcon.style.top = "12%";
  //     closeIcon.style.left = "3%";
  //     closeIcon.style.cursor = "pointer";
  //     closeIcon.style.width = "50px"; // Adjust size as needed
  //     closeIcon.style.height = "50px"; // Adjust size as needed
  //     closeIcon.style.background = "rgba(0, 0, 0, 0.5)";
  //     closeIcon.style.borderRadius = "50%";
  //     closeIcon.style.padding = "5px";
  //     closeIcon.style.border = "1px solid #ffffff";

  //     closeIcon.onclick = () => {
  //       // Close the document capture view by removing the video container
  //       document.body.removeChild(videoContainer);
  //     };

  //     const captureButton = document.createElement("button");
  //     captureButton.innerText = "Capture Document";
  //     captureButton.style.padding = "10px 20px";
  //     captureButton.style.width = "250px";
  //     captureButton.style.marginTop = "10px";
  //     captureButton.style.color = "#fff";
  //     captureButton.style.backgroundColor = "#0f99d6";
  //     captureButton.style.border = "none";
  //     captureButton.style.cursor = "pointer";
  //     captureButton.style.fontSize = "14px";
  //     captureButton.style.borderRadius = "5px";
  //     captureButton.style.zIndex = "1002";

  //     captureButton.onclick = async () => {
  //       // Get the position and size of the square overlay relative to the viewport
  //       const squareBounds = squareOverlay.getBoundingClientRect();

  //       // Get the position and size of the video element
  //       const videoBounds = videoElement.getBoundingClientRect();

  //       // Calculate the offset of the squareOverlay within the video element (considering scaling)
  //       const offsetX = squareBounds.left - videoBounds.left; // Adjust X based on video position
  //       const offsetY = squareBounds.top - videoBounds.top; // Adjust Y based on video position

  //       // Set the canvas size to match the dimensions of the square overlay
  //       canvas.width = squareBounds.width;
  //       canvas.height = squareBounds.height;

  //       // Capture the part of the video inside the square
  //       context?.drawImage(
  //         videoElement,
  //         offsetX, // Start at the adjusted X position inside the video element
  //         offsetY, // Start at the adjusted Y position inside the video element
  //         squareBounds.width, // Width of the square (same as canvas)
  //         squareBounds.height, // Height of the square (same as canvas)
  //         0,
  //         0, // Draw to the canvas starting at (0, 0)
  //         squareBounds.width, // Canvas width
  //         squareBounds.height // Canvas height
  //       );

  //       // Convert the captured part to Base64
  //       const imageData = canvas.toDataURL("image/png");

  //       // Optionally, show the captured image to the user for confirmation
  //       const previewImage = document.createElement("img");
  //       previewImage.src = imageData;
  //       previewImage.style.maxWidth = "80%"; // Style the preview image
  //       previewImage.style.maxHeight = "80%";

  //       // Append the image preview to the body (or a modal if you prefer)
  //       // document.body.appendChild(previewImage);

  //       // Stop the camera and hide the video container (optional)
  //       // stream.getTracks().forEach((track) => track.stop()); // Uncomment if you want to stop the camera

  //       // Show the cropped image for confirmation
  //       const confirmationContainer = document.createElement("div");
  //       confirmationContainer.style.position = "fixed";
  //       confirmationContainer.style.top = "0";
  //       confirmationContainer.style.left = "0";
  //       confirmationContainer.style.width = "100%";
  //       confirmationContainer.style.height = "100%";
  //       confirmationContainer.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
  //       confirmationContainer.style.display = "flex";
  //       confirmationContainer.style.flexDirection = "column";
  //       confirmationContainer.style.alignItems = "center";
  //       confirmationContainer.style.justifyContent = "center";
  //       confirmationContainer.style.zIndex = "1001";

  //       const imagePreview = document.createElement("img");
  //       imagePreview.src = imageData;
  //       imagePreview.style.maxWidth = "80%";
  //       imagePreview.style.maxHeight = "80%";
  //       imagePreview.style.marginBottom = "15px";

  //       const confirmButton = document.createElement("button");
  //       confirmButton.innerText = "Confirm";
  //       confirmButton.style.width = "100%";
  //       confirmButton.style.padding = "10px 20px";
  //       confirmButton.style.color = "#fff";
  //       confirmButton.style.backgroundColor = "#0f99d6";
  //       confirmButton.style.border = "none";
  //       confirmButton.style.cursor = "pointer";
  //       confirmButton.style.fontSize = "14px";
  //       confirmButton.style.borderRadius = "5px";

  //       const retakeButton = document.createElement("button");
  //       retakeButton.innerText = "Retake";
  //       retakeButton.style.width = "100%";
  //       retakeButton.style.padding = "10px 20px";
  //       retakeButton.style.marginTop = "10px";
  //       retakeButton.style.color = "#0f99d6";
  //       retakeButton.style.backgroundColor = "#fff";
  //       retakeButton.style.border = "none";
  //       retakeButton.style.cursor = "pointer";
  //       retakeButton.style.fontSize = "14px";
  //       retakeButton.style.borderRadius = "5px";

  //       confirmButton.onclick = async () => {

  //         // Stop camera and close the popup
  //         stream.getTracks().forEach((track) => track.stop());
  //         document.body.removeChild(confirmationContainer);

  //         // Create a new PDF document
  //         const pdf = new jsPDF();

  //         // Get image dimensions
  //         const imgWidth = imagePreview.width; // Image width in pixels
  //         const imgHeight = imagePreview.height; // Image height in pixels

  //         // Convert the Base64 image to PDF
  //         pdf.addImage(imageData, "PNG", 10, 10, imgWidth, imgHeight); // Adjust margins (10, 10) as needed

  //         // Save the PDF (optionally, you can give a name)
  //         const pdfBlob: any = pdf.output("blob"); // Get the PDF as a Blob
  //         console.log("PDF Blob", pdfBlob);
  //         const base64Pdf: any = await convertPDFToBase64(pdfBlob);
  //         console.log("base64Pdf", base64Pdf);
  //         setImageBase64(pdfBlob);
  //         setResidenceDoc(pdfBlob);

  //         // // Optionally, download the PDF
  //         // pdf.save("captured-image.pdf");

  //         // Get the size of the PDF (in KB, MB, etc.)
  //         const pdfSize = (pdfBlob.size / 1024).toFixed(2); // Convert to KB and round to 2 decimal places
  //         console.log("PDF size:", pdfSize, "KB");

  //         // Optionally, display the PDF size to the user
  //         const sizeText = document.createElement("p");
  //         sizeText.innerText = `PDF size: ${pdfSize} KB`;
  //         confirmationContainer.appendChild(sizeText);
  //         setShowResidenceVerfDocument(true);
  //         setFileType("image/png");
  //         setFileSize(`${pdfSize} KB`);
  //       };

  //       retakeButton.onclick = () => {
  //         // Hide confirmation and retake the image
  //         document.body.removeChild(confirmationContainer);
  //         document.body.appendChild(videoContainer); // Show video again
  //         videoElement.play();
  //       };

  //       const confirmationButtonContainer = document.createElement("div");
  //       confirmationButtonContainer.style.display = "flex";
  //       confirmationButtonContainer.style.flexDirection = "column";
  //       confirmationButtonContainer.style.width = "250px";
  //       // confirmationButtonContainer.style.f = 'space-between';
  //       confirmationButtonContainer.appendChild(confirmButton);
  //       confirmationButtonContainer.appendChild(retakeButton);

  //       confirmationContainer.appendChild(imagePreview);
  //       confirmationContainer.appendChild(confirmationButtonContainer);

  //       // Remove video container (optional, you can show it again later if needed)
  //       document.body.removeChild(videoContainer);
  //       document.body.appendChild(confirmationContainer); // Show the confirmation screen
  //     };

  //     currentStream = stream; // Store the stream for future use
  //     const videoElement = document.createElement("video");
  //     videoElement.srcObject = stream;
  //     videoElement.play();

  //     // Append the message overlay to the video container
  //     videoContainer.appendChild(messageOverlay);
  //     videoContainer.appendChild(videoElement);
  //     if (isMobile) {
  //       videoContainer.appendChild(flipIcon);
  //       videoContainer.appendChild(closeIcon);
  //     }
  //     videoContainer.appendChild(captureButton);
  //     videoContainer.appendChild(squareOverlay); // Add the square overlay
  //     document.body.appendChild(videoContainer);
  //   } catch (error: any) {
  //     if (error.name === "NotAllowedError") {
  //       alert("Camera permissions are required.");
  //     } else {
  //       alert("An error occurred while accessing the camera: " + error.message);
  //     }
  //   }
  // };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    console.log('Files', files);
    if (files) {
      handleFiles(Array.from(files));
    }
  };

  const handleFiles = async (files: File[]) => {
    const validFiles = files.filter((file) =>
      ["image/png", "image/jpeg", APPLICATION_PDF].includes(file.type)
    );

    setUploadedFiles((prev) => [...prev, ...validFiles]);

    // Convert each file into a Base64 string
    const base64Promises = validFiles.map((file) => convertToBase64(file));
    const base64Results = await Promise.all(base64Promises);

    // Save Base64 results to state
    setBase64Files((prev) => [...prev, ...base64Results]);

    handleFileDetails(files);
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleFiles(Array.from(event.dataTransfer.files));
  };

  const handleFileDetails = (files: any) => {
    const fileSizeInBytes = files[0].size; // Get file size in bytes
    setFileType(files[0].type); // Get file type
    setResidenceDoc(files[0]);
    // Convert bytes to KB, MB, or GB
    let fileSize: any = fileSizeInBytes;
    let sizeUnit = "bytes";

    if (fileSizeInBytes >= 1024) {
      fileSize = fileSizeInBytes / 1024;
      sizeUnit = "KB";
    }

    if (fileSize >= 1024) {
      fileSize = fileSize / 1024;
      sizeUnit = "MB";
    }

    if (fileSize >= 1024) {
      fileSize = fileSize / 1024;
      sizeUnit = "GB";
    }

    // Round the file size to 2 decimal places
    fileSize = fileSize.toFixed(2);

    // Set the file size to the state
    setFileSize(`${fileSize} ${sizeUnit}`);
    if (SUPPORTED_FILE_TYPE.includes(files[0].type)) {
      if (sizeUnit !== "KB") {
        if ((sizeUnit == "MB" && Number(fileSize) >= 5 && files[0].type == 'application/pdf')) {
          setMessagePopup(true);
          setErrorMessage("Please upload a file smaller than 5MB.");
          setResidenceDoc("");
        } else if ((sizeUnit == "MB" && Number(fileSize) > 20) || sizeUnit == "GB") {
          setMessagePopup(true);
          setErrorMessage("Please upload a file smaller than 20MB.");
          setResidenceDoc("");
        } else {
          setShowResidenceVerfDocument(true);
        }
      } else {
        setShowResidenceVerfDocument(true);
      }
    } else {
      setMessagePopup(true);
      setErrorMessage(
        "Invalid file format. Please upload a PDF, JPEG, or PNG."
      );
      setResidenceDoc("");
    }
  };

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(new Error("Failed to convert file."));
      reader.readAsDataURL(file);
    });
  };

  // handleInputChange function

  const handleInputChange = (field?: any, value?: any) => {
    if (field == 'landLordPhoneNumber') {
      const response = Validator(value, 'phoneNumber')
      setLandlordResidence({
        ...landlordResidence, formatedMobileNumber: response[2],
        landLordPhoneNumber: response[0]
      })

    }
    else {
      setLandlordResidence({ ...landlordResidence, [field]: formatText(value) });

    }

  };

  //  Residence Tab Save Click

  const ResidenceSaveClick = async () => {

    setShowResidenceDetails(true);
    let payload: any = ''
    if (residenceType == 'RENT') {
      payload = {
        customerId: customerId,
        ...(!residenceDoc ? {
          landlordReferences: [
            {
              landlordFirstName: landlordResidence?.landLordName,
              phoneNumber: landlordResidence?.landLordPhoneNumber,
              moveInDate: null,
              active: "Y",
              leaseLengthYears: "0.000000",
              monthlyRent: "",
              leaseLengthMonths: "0.000000",
              leaseTerm: null,
              leaseCompanyName: null,
              phoneExtension: null,
              addressLine1: null,
              addressLine2: null,
              city: null,
              state: null,
              postalCode: "",
              leaseHolderName: null,
              bestTimeToCall: null,
              verifiedDate: "",
              landlordReferenceId: landlordResidence?.landlordReferenceId ? landlordResidence?.landlordReferenceId : '',
              residenceTenureType: landlordResidence?.timeAtCurrentAddress ? landlordResidence?.timeAtCurrentAddress : null
            },
          ]
        } : undefined)
        ,
        residenceType: "RENT",
      }
      landlordResidence?.landLordName || landlordResidence?.landLordPhoneNumber || landlordResidence?.timeAtCurrentAddress ? setLandlordResidenceCopy({ ...landlordResidenceCopy, landLordName: landlordResidence?.landLordName, timeAtCurrentAddress: landlordResidence?.timeAtCurrentAddress, landLordPhoneNumber: landlordResidence?.landLordPhoneNumber, residenceDocCopy: residenceDoc }) : setLandlordResidenceCopy({ ...landlordResidenceCopy, residenceDocCopy: residenceDoc })
    }
    else {
      residenceDoc ? setLandlordResidenceCopy({ ...landlordResidenceCopy, residenceDocCopy: residenceDoc }) : null
      payload = {
        customerId: customerId,
        residenceType: "OWN"
      }
    }

    setResFileSizeCopy(fileSize)
    setResFileTypeCopy(fileType)

    if (residenceDoc) {
      console.log("Resdoc data", residenceDoc);
      const documentData = await handleFile(residenceDoc);
      console.log("Document data", documentData);

      uploadDocuments(approvalId, [
        {
          documentType: "RESDOC",
          file: documentData?.base64String,
        },
      ]);
    }

    UpdateCustomerInfo(payload, isStoreCodeFlow, accessToken)
    if (source == "review") {
      history.push(`/review/details/${customerId}/${approvalId}`);
    } else {
      history.push(`/${getNextNavigator('Residence', dynamicHeader)}/${customerId}/${approvalId}`);
      updateCustomerApproval("RESIDENCE", approvalId, customerId);
    }
  };

  const pdfModelContent = () => {
    return <FileToPDFViewer file={residenceDoc} />;
  };

  const handleInputChanges = (e: any) => {
    e.target.value = null;
  };

  // handleInputChange function

  return (
    <Grid>
      <ScrollToTop/>
      {loader && <ScreenLoader />}

      {messagePopup ? (
        <MessagePopup
          value={{
            message: errorMessage,
            setFunction: setMessagePopup,
            cleanupFunction: setErrorMessage,
          }}
        />
      ) : null}
      {/*DOFHeader is used to dynamically display the RACLogo and Progress Bar based on the Tab and BusinessType. 
      Simply provide the activeStep as your current tab, mark the completed tabs as true, and set the current and 
      next step tabs as false.*/}
      <Grid>
        <DOFHeader
          identity={true}
          residence={false}
          income={false}
          reference={false}
          activeStep="Residence"
        />
      </Grid>
      {/*getTabsHeading is used to dynamically bind headings based on the Tab. Just provide the tab name, 
      and it will return the heading.*/}
      <Grid>{getTabsHeading("residence")}</Grid>
      <Grid className={`${classes.leftAlign} ${classes.tabGridStyle}`}>
        <Grid
          className={`${classes.leftAlign} ${classes.minWidth38p} ${classes.p16px}`}
        >
          <Grid>
            <Typography>
              Do you rent or own? <span style={{ color: "red" }}>*</span>
            </Typography>

            <RACRadio
              // disabled={residenceType != 'RENT'}
              checked={residenceType == "RENT"}
              onChange={() => {
                setResidenceType("RENT");
              }}
              value={""}
              label="Rent"
            />

            <RACRadio
              // disabled={residenceType != 'OWN'}
              checked={residenceType == "OWN"}
              onChange={() => {
                setResidenceType("OWN");
              }}
              value={""}
              label="Own"
            />
          </Grid>
          <Typography className={`${classes.boldFont1}`}>
            Proof of Residence
          </Typography>
          <Typography
            className={`${classes.leftAlign} ${classes.pl16px} ${classes.pt10px}`}
          >
            Accepted proof of residence documents, listed below (choose one):
          </Typography>
          <ul className={`${classes.pl35px}`}>
            {RESIDENCE_TAB_DOCUMENT_LIST.map((doc: any) =>
              doc.type == BooleanRepresentation.Primary ? (
                <li className={`${classes.pb5px}`}>{doc.value}</li>
              ) : doc.type == BooleanRepresentation.Secondary &&
                showSecondaryDoc ? (
                <li className={`${classes.pb5px}`}>{doc.value}</li>
              ) : null
            )}
          </ul>
          <Typography
            className={`${classes.cursorPointer} ${classes.leftAlign} ${classes.pl16px} ${classes.primaryFontColor} ${classes.underline}`}
            onClick={() => setShowSecondaryDoc(!showSecondaryDoc)}
          >
            {showSecondaryDoc
              ? `Hide Document`
              : `View More Documents Examples`}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={`${classes.tabGridStyle} ${classes.width100p}`}>
        <Grid className={`${classes.tabGridStyle}`}>
          <Typography
            variant="h6"
            className={`${classes.font16px} ${classes.p10px}`}
          >
            Choose any method below
          </Typography>

          {/* <RACButton
                variant="outlined"
                color="primary"
                onClick={openCamera}
                startIcon={<CameraIcon />}
                className={`${classes.mb15px} ${classes.buttonBorderColor} ${classes.blackFont} ${classes.font16px} ${classes.w91p}`}
              >
                OPEN CAMERA AND TAKE PHOTO
              </RACButton> */}
          {DocumentCapture(setResidenceDoc, setFileSize, setFileType)}

          <Paper
            className={`${classes.drapNDropStyling} ${classes.w91p} ${classes.mb20px}`}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <UploadIcon className={`${classes.mt20px} ${classes.mb15px}`} />
            </Box>
            <label>
              <input
                type="file"
                accept=".png,.jpeg,.jpg,.pdf"
                multiple
                onChange={(e) => {
                  if (landlordResidence?.landLordName || landlordResidence?.timeAtCurrentAddress || landlordResidence?.phoneNumber) {
                    handleInputChanges(e);
                  }
                  else {
                    handleFileChange(e);
                    handleInputChanges(e);
                  }

                }}
                style={{ display: "none" }}
              />
              <Typography
                variant="body2"
                component="span"
                color="primary"

                className={`${classes.underline} ${classes.cursorPointer} ${classes.primaryFontColor} ${classes.font16px}`}
              >
                Choose a file or drag it here
              </Typography>
            </label>
            <Typography
              variant="body2"
              className={`${classes.mt10px} ${classes.lightGreyColor} ${classes.font14px} ${classes.p10px}`}
            >
              JPEG, PNG, PDF up to 2 MB
            </Typography>
          </Paper>

          {showResidenceVerfDocument && (
            <Grid
              style={{
                width: "100%",
                textAlign: "left",
                padding: "10px",
                display: "flex",
                paddingTop: "0px",
                cursor: "pointer",
              }}
            >
              {fileType &&
                (fileType == "image/png" || fileType == "image/jpeg") && (
                  <ImageIcon style={{ width: "20px", height: "20px" }} />
                )}
              {fileType && fileType == APPLICATION_PDF && (
                <PdfIcon style={{ width: "20px", height: "20px" }} />
              )}
              <Typography
                className={`${classes.primaryFontColor} ${classes.pl10px}`}
                onClick={() => {
                  setOpenPdf(true);
                }}
              >
                Proof of Residence{" "}
                <span className={`${classes.fileSizeColor}`}>{fileSize}</span>
              </Typography>
              <DeleteIcon
                style={{
                  width: "15px",
                  height: "15px",
                  marginLeft: "10px",
                  marginTop: "3px",
                }}
                onClick={() => {
                  setShowResidenceVerfDocument(false);
                  setResidenceDoc('')
                }}
              />
            </Grid>
          )}



          {residenceType == "RENT" && !residenceDoc ? (
            <>
              <Grid>
                <Typography style={{ marginBottom: "5px" }}>
                  <span
                    className={classes.manulEntry}
                    onClick={() => {
                      setManualEntryEnabled(!manualEntryEnabled);
                    }}
                  >
                    Enter details manually{" "}
                    {!manualEntryEnabled ? <DownArrowIcon /> : <UpArrowIcon />}
                  </span>
                </Typography>
              </Grid>
              {manualEntryEnabled ? (
                <Grid className={classes.residenceManulEntry}>
                  <RACTextbox
                    isCurrency={false}
                    disabled={residenceDoc}
                    required={true}
                    type={"text"}
                    inputlabel={"Landlord’s / Apartment Name "}
                    OnChange={(e) =>
                      handleInputChange("landLordName", e.target.value)
                    }
                    maxlength={25}
                    value={
                      landlordResidence.landLordName
                    }
                  />
                  <div className={classes.racdropdownleftalign}>
                    <RACSelect
                      inputLabel="Time at Current Address "
                      name="timeatCurrentAddress"
                      required={true}
                      isDisabled={residenceDoc}
                      defaultValue={
                        landlordResidence.timeAtCurrentAddress
                      }
                      options={residenceTenureTypeOptions}
                      onChange={(e) =>
                        handleInputChange("timeAtCurrentAddress", e.target.value)
                      }
                    />
                  </div>

                  <RACTextbox
                    required={true}
                    inputlabel={"Landlord/Leasing Office Number"}
                    OnChange={(e) =>
                      handleInputChange("landLordPhoneNumber", e.target.value)
                    }
                    maxlength={14}
                    value={
                      landlordResidence.formatedMobileNumber
                    }
                    disabled={residenceDoc}

                  />
                </Grid>
              ) : null}
            </>
          ) : null}



          <RACButton
            variant="contained"
            color="primary"
            onClick={() => {
              ResidenceSaveClick();
            }}
            className={`${classes.mb15px} ${classes.font16px} ${classes.primaryButtonColor} ${classes.w91p}`}
            disabled={saveButtonDisable}
          >
            SAVE & CONTINUE
          </RACButton>

          {source == "review" && (
            <RACButton
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push(`/review/details/${customerId}/${approvalId}`);
              }}
              className={`${classes.mb15px} ${classes.font16px} ${classes.buttonBorderColor} ${classes.primaryFontColor} ${classes.w91p}`}
            >
              BACK
            </RACButton>
          )}

          <RACModalCard
            borderRadius="15px"
            closeIcon={true}
            isOpen={openPdf}
            maxWidth={"sm"}
            className={`${classes.customModal}`}
            title={`Proof of Residence`}
            onClose={() => setOpenPdf(false)}
          >
            {pdfModelContent()}
          </RACModalCard>

          {getNoteText()}
        </Grid>
      </Grid>
    </Grid>
  );
};
