/* eslint-disable */
import { Grid, Typography, RACButton } from "@rentacenter/racstrap";

import doflogo from "../../../images/logo.svg";
import { useContext, useEffect, useState } from "react";
import { FindCustomer } from "../../../api/user";
import { DigitalOrderFormContext } from "../Context/DigitialOrderFormContext";
import ScreenLoader from "../Common/ScreenLoader";
import { getCustomerDetails } from "../Common/GetCustomerDetails";
import { VerificationStyles } from "../../../styles/verificationStyles";
import { useHistory, useParams } from "react-router-dom";
import { ReferenceTabContext } from "../Context/ReferenceDetailsContext";
import { ResidenceTabContext } from "../Context/ResidenceDetailsContext";

export const WelcomePage = () => {
  const { decisionEngineDetails, setCVOCustomerDetails } = useContext(
    DigitalOrderFormContext
  );

  const {
    setFamilyReferenceDetails,
    setnonFamilyReferenceDetails,
    nonFamilyReferenceDetails,
    familyReferenceDetails

  } = useContext(ReferenceTabContext);
  const { landlordResidence,
    setLandlordResidence } = useContext(ResidenceTabContext);
  const [screenLoader, setScreenLoader] = useState(false);
  const classes: any = VerificationStyles();
  const queryParams = new URLSearchParams(window.location.search);
  const paramValue = queryParams.get("status");
  const { approvalId } = useParams<any>();
  const history = useHistory();
  const dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues");
  const sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  const clientId: any = sessionStorageValues?.clientId

  useEffect(() => {


    pageLoad();
    // Call sendHeightToParent initially to send the height on mount
    sendHeightToParent();
    // Add an event listener for window resizing
    const handleResize = () => {
      sendHeightToParent();
    };
    window.addEventListener("resize", handleResize);
  }, []);

  // Adding the Google Analytics tags

  useEffect(() => {
    window.dataLayer?.push({
      'event': 'Loaded the DOF Welcome Page',
      'storeNo': dofAuthSessionValue?.storeNumber,
      'approvalId': dofAuthSessionValue?.approvalId,
      'currentScreen': 'DOF Welcome Page',
      'previousScreen': 'This is the First Screen',
    });
  }, [])



  const sendHeightToParent = () => {
    const iframeHeight = document.body.scrollHeight; // Get the current height of the iframe content
    const iframeWidth = document.body.scrollWidth;
    window.parent.postMessage(
      {
        message: "racpad-styling",
        minHeight: iframeHeight < 540 ? 540 : iframeHeight,
        currentHeight: iframeHeight,
        iframeWidth: iframeWidth,
        verificationProcessCompleted: false
      },
      "*"
    );
  };

  const pageLoad = async () => {
    if (decisionEngineDetails?.globalCustomerId) {
      const getCustomerDetailsRes: any = await FindCustomer(
        { GcId: decisionEngineDetails?.globalCustomerId },
        sessionStorageValues?.authKey
          ? false
          : window.sessionStorage.getItem("storeNumber"),
        sessionStorageValues?.authKey
          ? sessionStorageValues?.authKey
          : window.sessionStorage.getItem("storeNumber")
      );
      if (getCustomerDetailsRes?.customerId) {
        await getCustomerDetails(
          getCustomerDetailsRes?.customerId,
          setCVOCustomerDetails,
          landlordResidence,
          setLandlordResidence,
          familyReferenceDetails,
          setFamilyReferenceDetails,
          nonFamilyReferenceDetails,
          setnonFamilyReferenceDetails
        );
      }
    }
  };

  const handleNext = () => {
    if ((atob(clientId) == "EC" || atob(clientId) == "ECOM")) {
      history.push(`/dof/verification/${approvalId}`);
    } else {
      history.push(`/otp/auth/${approvalId}`);
    }
  };

  return (
    <Grid style={{ width: "100%", height: "80vh" }}>
      <Grid style={{ marginTop: "7%" }}>
        {screenLoader ? <ScreenLoader /> : null}
        {(atob(clientId) == "EC" || atob(clientId) == "ECOM") ? null : (
          <Grid style={{ textAlign: "center", display: "inline-flex" }}>
            <img
              src={doflogo}
              style={{
                float: "left",
                verticalAlign: "middle",
                padding: "16px 24px",
              }}
            />
          </Grid>
        )}

        <Typography
          className={`${classes.primaryFontColor} ${classes.font18px} ${classes.boldFont1} ${classes.p10px}`}
        >
          Welcome to Rent A Center
        </Typography>

        <Typography className={`${classes.font14px} ${classes.p10px}`}>
          Let's begin with the verification process
        </Typography>

        <RACButton
          color="primary"
          variant="contained"
          style={{
            marginTop: "20px",
          }}
          className={`${classes.primaryButtonColor} ${classes.buttonsPadding}`}
          onClick={() => {
            handleNext();
          }}
        >
          Next
        </RACButton>
      </Grid>
    </Grid>
  );
};
