/* eslint-disable */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */

import { Grid, RACButton, RACTextbox, Typography } from "@rentacenter/racstrap";
import React, { useEffect, useState, useContext } from "react";
import { ReactComponent as OTPVerificationIcon } from "./../../../images/otp-mobile.svg";
import { ReactComponent as RACLogo } from "./../../../images/logo.svg";
import { ReactComponent as TimerIcon } from "./../../../images/timer-icon.svg";
import { VerificationStyles } from "../../../styles/verificationStyles";
import {
  generateDOFAccessLink,
  sendOneTimePasscode,
  validateOneTimePassword,
} from "../../../api/user";
import MessagePopup from "../Common/MessagePopup";
import { Validator as valid } from "../../../utils/utils";
import { useHistory, useParams } from "react-router-dom";
import { DigitalOrderFormContext } from '../Context/DigitialOrderFormContext';
import { dofContextValue } from "../../../context/DofContext";

export const OTPAuthenticationTab = () => {
  let { customerDetails, setCustomerDetails } = useContext(dofContextValue);
  const { setCustMobNumber } = useContext(DigitalOrderFormContext);
  const classes: any = VerificationStyles();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLookup, setIsLookUp] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mobileNumber, setMobileNumber] = useState<string>("");
  type phoneValidator = Array<string | boolean>;
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>(``);
  const [otpLength, setOTPLength] = useState<number>(5)
  const [otp, setOtp] = useState(Array(otpLength).fill(""));
  const [timeLeft, setTimeLeft] = useState(0);
  const [confirmBtnLoader, setConfirmButtonLoader] = useState<boolean>(false);
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const [messageId, setMessageId] = useState<string>("");
  const history = useHistory();
  const [otpError, setOTPError] = useState<boolean>(false);
  const [otpErrorMessage, setOTPErrorMessage] = useState<string>("");
  const { approvalId } = useParams<any>();
  const queryParams = new URLSearchParams(window.location.search);
  const paramValue = queryParams.get("session");

  let dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues");
  let sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
  const isStoreCodeFlow: any = sessionStorageValues?.storeNumber;
  const accessToken: any = sessionStorageValues?.storeNumber;
  useEffect(() => {
    // Call sendHeightToParent initially to send the height on mount
    sendHeightToParent();
    // Add an event listener for window resizing
    const handleResize = () => {
      sendHeightToParent();
    };
    window.addEventListener("resize", handleResize);
  }, [isLookup]);

  useEffect(() => {
    setOtp(Array(otpLength).fill(""))
  }, [otpLength])

  const sendHeightToParent = () => {
    const iframeHeight = document.body.scrollHeight; // Get the current height of the iframe content
    const iframeWidth = document.body.scrollWidth;
    window.parent.postMessage(
      {
        message: "racpad-styling",
        minHeight: iframeHeight < 540 ? 540 : iframeHeight,
        currentHeight: iframeHeight,
        iframeWidth: iframeWidth,
        verificationProcessCompleted: false
      },
      "*"
    );
  };

  const handleChange = (e: any, index: number) => {
    const value: any = e?.target?.value;

    if (e.key !== "Backspace" && value) {
      // Only allow numbers and ensure the value doesn't exceed 1 character
      if (/[^0-9]/.test(value)) {
        return;
      }

      // Update OTP array at the given index
      setOtp((prevOtp) =>
        prevOtp.map(
          (item, idx) => (idx === index ? value : item) // Replace value at current index with empty string
        )
      );

      // Move focus to the next input after entering a value
      if (value !== "" && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`)?.focus();
      }
    } else {
      // Handle backspace: If backspace is pressed, clear the current input
      setOtp((prevOtp) =>
        prevOtp.map(
          (item, idx) => (idx === index ? "" : item) // Replace value at current index with empty string
        )
      );
      if (value !== "" && index > 0) {
        document.getElementById(`otp-input-${index - 1}`)?.focus();
      }
    }
  };

  // Function to handle focus on previous input field
  const handleBackspace = (e: any, index: number) => {
    // // eslint-disable-next-line no-debugger
    // debugger;
    // if (e.key === 'Backspace' && index > 0) {
    //   if(index == 5){
    //     setOtp(prevOtp => prevOtp.map((item, idx) =>
    //       idx === 5 ? "" : item // Replace value at current index with empty string
    //     ));
    //   }
    //   // If the index is greater than 0, focus the previous input field
    //     document.getElementById(`otp-input-${index - 1}`)?.focus();
    // }
    if (e.key === "Enter" && index == otpLength - 1) {
      handleConfirmButtonClick();
    }
  };

  const sendOtp = async (isResendFlow?: boolean) => {
    setOTPErrorMessage("");
    const formatPhoneNumber: phoneValidator = valid(
      mobileNumber,
      'phoneNumber'
    ) as phoneValidator;
    console.log(formatPhoneNumber, 'number');

    setCustomerDetails({
      ...customerDetails,
      mobileNumber: formatPhoneNumber[2],
      unFormattedPhoneNumber: formatPhoneNumber[0],
    });
    setOTPError(false);
    if (!buttonLoader && !buttonDisable) {
      setButtonLoader(true);
      setCustMobNumber(mobileNumber)
      const sendOtpResp = await sendOneTimePasscode(
        mobileNumber,
        isStoreCodeFlow,
        accessToken
      );

      console.log("sendOtpResponse", sendOtpResp);

      if (sendOtpResp?.status == 200) {
        setButtonLoader(false);
        setConfirmButtonLoader(false);
        setButtonDisable(false);
        setMessageId(sendOtpResp?.data?.messageId);
        setOTPLength(sendOtpResp?.data?.otpLength);
        setIsLookUp(false);
        const date: any = Date.now();
        window.sessionStorage.setItem("otpStartTime", date);
        if (isResendFlow) {
          setOTPError(true);
          setOTPErrorMessage("OTP resent successfully.");
        }
      } else if (sendOtpResp?.status == 400) {
        setError(true);
        setButtonDisable(false);
        setConfirmButtonLoader(false);
        setButtonLoader(false);
        if (
          sendOtpResp?.data?.errors?.[0]?.code == 400 &&
          sendOtpResp?.data?.errors?.[0]?.error?.errors?.[0]?.message
        ) {
          setErrorMessage(
            sendOtpResp?.data?.errors?.[0]?.error?.errors?.[0]?.message
          );
        } else {
          setErrorMessage(
            `Please provide a valid mobile number and try again.`
          );
        }
      } else {
        setError(true);
        setButtonDisable(false);
        setConfirmButtonLoader(false);
        setButtonLoader(false);
        setErrorMessage("Something went wrong. Please try again.");
      }
    }
  };

  const validateOtp = async () => {
    const payload = {
      enteredOtp: otp.join(""),
      messageId: messageId,
      phoneNumber: mobileNumber,
    };

    const validateOtpResp = await validateOneTimePassword(
      payload,
      isStoreCodeFlow,
      accessToken
    );

    console.log("validateOtpResp", validateOtpResp);

    if (validateOtpResp?.status == 200 && validateOtpResp?.data?.isValid) {
      if (paramValue == 'timeout') {
        let dofAuthSessionValue: any = window.sessionStorage.getItem("dofAuthValues");
        let sessionStorageValues: any = JSON.parse(dofAuthSessionValue);
        console.log('Session Storage details', sessionStorageValues);

        const generateLinkPayload: any = {
          source: atob(sessionStorageValues?.clientId),
          userId: sessionStorageValues?.encodeUserId,
          storeNumber: sessionStorageValues?.storeNumber,
          approvalId: sessionStorageValues?.approvalId,
          phoneNumber: mobileNumber,
          sendLinkToCustomer: false
        }

        const generateLinkResp = await generateDOFAccessLink(generateLinkPayload, isStoreCodeFlow, accessToken);

        console.log('GenerateLinkResp', generateLinkResp);

        if (generateLinkResp?.status == 200 && generateLinkResp?.data?.digitalOrderFormURL) {
          sessionStorage.clear();
          window.location.href = `${generateLinkResp?.data?.digitalOrderFormURL}&navpage=otpverified`
        }
      }
      else {
        approvalId ? history.push(`/otp/verified/${approvalId}`) : history.push(`/otp/verified`)
      }
      setConfirmButtonLoader(false);
      setButtonDisable(false);
      window.sessionStorage.removeItem('otpStartTime');
    } else if (validateOtpResp?.status == 400 && !validateOtpResp?.data?.errors?.[0]?.error?.isValid) {
      setOTPError(true);
      setConfirmButtonLoader(false);
      setButtonDisable(false);
      setOTPErrorMessage(`Please enter the valid OTP to proceed.`);
    } else {
      setOTPError(true);
      setConfirmButtonLoader(false);
      setButtonDisable(false);
      setOTPErrorMessage(`Something went wrong. Please try again.`);
    }
  };

  useEffect(() => {
    // Duration for OTP validity: 3 minutes
    const OTP_VALIDITY_DURATION = 3 * 60 * 1000; // 3 minutes in milliseconds

    // Check if the timer is already set in sessionStorage
    const storedStartTime: any =
      sessionStorage.getItem("otpStartTime") || Date.now();

    let startTime;
    if (storedStartTime) {
      startTime = parseInt(storedStartTime, 10);
    } else {
      // If not set, initialize the timer
      startTime = Date.now();
      sessionStorage.setItem("otpStartTime", startTime.toString());
    }

    // Calculate the expiry time
    const expiryTime = startTime + OTP_VALIDITY_DURATION;

    // Update the timer every second
    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      const remainingTime = Math.max(expiryTime - currentTime, 0); // Avoid negative values

      setTimeLeft(remainingTime);

      if (remainingTime === 0) {
        clearInterval(intervalId); // Clear the timer when it reaches 0
        sessionStorage.removeItem("otpStartTime"); // Optionally clear session storage
      }
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [isLookup, timeLeft]);


  // Adding the Google Analytics tags
  useEffect(() => {

    window.dataLayer?.push({
      'event': 'Loaded the DOF OTP Authentication Page',
      'storeNo': sessionStorageValues?.storeNumber,
      'approvalId': sessionStorageValues?.approvalId,
      'currentScreen': 'DOF OTP Authentication Screen',
      'previousScreen': 'DOF Welcome Screen',
    });
  }, [])

  const handleConfirmButtonClick = () => {
    setConfirmButtonLoader(true);
    setButtonDisable(true);
    setOTPError(false);
    setOTPErrorMessage("");
    validateOtp();
  };

  // Convert timeLeft (milliseconds) to minutes and seconds
  const minutes = Math.floor(timeLeft / 60000);
  let seconds: any = Math.floor((timeLeft % 60000) / 1000);
  seconds = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <Grid>
      {error ? (
        <MessagePopup
          value={{
            message: errorMessage,
            setFunction: setError,
            cleanupFunction: setErrorMessage,
          }}
        />
      ) : null}
      <Grid className={`${classes.tabGridStyle} ${classes.mt10px}`}>
        <Grid className={`${classes.p16px}`}>
          <RACLogo
            style={{ width: "100px", height: "150px", padding: "10px" }}
          />
          {isLookup && paramValue == "timeout" ? (
            <Typography
              className={`${classes.errorMessageColor} ${classes.boldFont1} ${classes.mb20px}`}
            >
              {`Oops! It looks like your session expired. Please log in again to continue.`}
            </Typography>
          ) : null}
          <Typography
            className={`${classes.primaryFontColor} ${classes.font18px} ${classes.boldFont1}`}
          >
            {isLookup ? `Let's look up your info !` : `Verification Code`}
          </Typography>
          <OTPVerificationIcon
            style={{ width: "100px", height: "150px", padding: "10px" }}
          />
          <Grid className={`${classes.p20px} ${classes.pl25px}`}>
            <Typography
              className={`${classes.tabGridStyle} ${classes.font14px} ${classes.textAlignLeft}`}
            >
              {isLookup ? (
                `Please enter your mobile number and we will send you a verification code`
              ) : (
                <Typography
                  component="span"
                  className={`${classes.font14px} ${classes.textAlignLeft}`}
                >
                  Enter the verification code sent to
                  <Typography
                    component="span"
                    className={`${classes.font14px} ${classes.boldFont1} ${classes.textAlignLeft} ${classes.pl10px}`}
                  >
                    {` (${mobileNumber.slice(0, 3)}) ${mobileNumber.slice(
                      3,
                      6
                    )}-${mobileNumber.slice(6, 10)}`}
                  </Typography>
                </Typography>
              )}
            </Typography>
            {isLookup ? (
              <RACTextbox
                inputlabel="Mobile Number"
                required={true}
                value={
                  mobileNumber.length === 10
                    ? `(${mobileNumber.slice(0, 3)}) ${mobileNumber.slice(
                      3,
                      6
                    )}-${mobileNumber.slice(6, 10)}`
                    : mobileNumber
                }
                disabled={false}
                maxlength={14}
                inputLabelClassname={`${classes.mt20px}`}
                className={`${classes.mt10px}`}
                type="text"
                OnChange={(e: any) => {
                  console.log("Mobile Number", e.target.value);
                  const cleaned = ("" + e.target.value).replace(/\D/g, "");
                  setMobileNumber(cleaned);
                }}
                OnKeydown={(e) => {
                  if (e.key == "Enter" && mobileNumber.length >= 10) {
                    sendOtp();
                  }
                }}
              />
            ) : (
              <Grid>
                <Grid
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {otp.map((value, index) => (
                    <RACTextbox
                      key={index}
                      value={value}
                      id={`otp-input-${index}`}
                      OnChange={(event: any) => handleChange(event, index)} // Handle change for each textbox
                      OnKeydown={(e) => handleBackspace(e, index)}
                      maxlength={1} // Restrict input to one character
                      placeHolder=" " // Optional placeholder
                      type="text" // Specify the input type
                      className={`${classes.otpInputClass} ${classes.removeFocus}`}
                    />
                  ))}
                </Grid>
                {otpError ? (
                  <Grid
                    className={`${classes.width100p} ${classes.mt10px} ${classes.textAlignLeft}`}
                  >
                    <Typography
                      className={
                        otpErrorMessage.includes("resent")
                          ? `${classes.successMessageColor}`
                          : `${classes.errorMessageColor}`
                      }
                    >
                      {otpErrorMessage}
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid
                  className={`${classes.width100p} ${classes.mt10px} ${classes.timerStyle}`}
                >
                  <Grid className={`${classes.mt10px} ${classes.timerDiv}`}>
                    <TimerIcon style={{ marginTop: "3px" }} />
                    <Typography
                      component="span"
                      className={`${classes.font14px} ${classes.boldFont1} ${classes.pl10px}`}
                    >
                      {`0${minutes}:${seconds}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className={`${classes.width100p} ${classes.mt10px}`}>
                  <Grid
                    className={`${classes.mt10px} ${classes.displayFlex} ${classes.justifyContentCenter}`}
                  >
                    <Typography
                      component="span"
                      className={`${classes.lightGreyColor} ${classes.font14px}`}
                    >
                      Didn’t you receive the OTP?
                    </Typography>
                    <Typography
                      component="span"
                      className={`${classes.primaryFontColor} ${classes.font14px} ${classes.cursorPointer} ${classes.pl10px}`}
                      onClick={() => {
                        setButtonDisable(true);
                        setOtp(Array(otpLength).fill(""));
                        sendOtp(true);
                      }}
                    >
                      Resend OTP
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {isLookup ? (
              <RACButton
                variant="contained"
                color="primary"
                className={`${classes.primaryButtonColor} ${classes.font14px} ${classes.mt20px}`}
                disabled={mobileNumber.length < 10 || buttonLoader}
                onClick={() => {
                  sendOtp();
                }}
                loading={buttonLoader}
              >
                Verify
              </RACButton>
            ) : (
              <Grid
                className={`${classes.width100p} ${classes.displayFlex} ${classes.spaceAround} ${classes.mt10px}`}
              >
                <RACButton
                  variant="outlined"
                  color="secondary"
                  className={`${classes.font14px} ${classes.mt20px} ${classes.buttonBorderColor} ${classes.primaryFontColor}`}
                  disabled={mobileNumber.length < 10 || buttonDisable}
                  onClick={() => {
                    setIsLookUp(true);
                    setButtonLoader(false);
                    setOtp(Array(6).fill(""));
                  }}
                >
                  Previous
                </RACButton>
                <RACButton
                  variant="contained"
                  color="primary"
                  className={`${classes.primaryButtonColor} ${classes.font14px} ${classes.mt20px}`}
                  disabled={
                    mobileNumber.length < 10 ||
                    buttonDisable ||
                    otp.join("").length < otpLength
                  }
                  onClick={() => {
                    handleConfirmButtonClick();
                  }}
                  loading={confirmBtnLoader}
                >
                  Confirm
                </RACButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};